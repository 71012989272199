import { Col, Form, Input, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useMatch } from "react-router-dom";

import {
  getPublisherInvoices,
  getPublisherInvoicesPayout,
} from "@/app/local/invoiceSlice";
import SelectPublisher from "@/components/input/selectPublisher";
import {
  CONTRACT_STATUS,
  InvoiceStatus,
  LEGAL_STATUS,
  PaymentStatus,
  USER_TYPE,
} from "@/utils/constants/apiConstants";
import { MANUAL_CONFIG } from "@/utils/constants/configConstants";
import {
  dateFormat,
  objectR,
  priceFormat,
  serverAcceptableDate,
} from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";
import { getFinalFilters, getLastParams } from "@/utils/utils";

import MainContainer from "../container/MainContainer";
import PageTitle from "../general/PageTitle";
import SliderMenu from "../general/SliderMenu";
import TableComponent from "../general/TableComponent";
import InputRangePicker from "../input/inputRangePicker";
import FormSearchBar from "../micro/FormSearchBar";
import TagCustom from "../micro/TagCustom";
import FormPayoutStatusPublisher from "./FormPayoutStatusPublisher";
import FormStatusPublisher from "./FormStatusPublisher";

const PayoutPublisherList = (props) => {
  const { setFilters } = props;
  const isPayoutPage = useMatch("payouts");
  const { invoicesPublisher, invoicesPublisherLoading } = useSelector(
    (state) => state.invoice
  );
  const dispatch = useDispatch();
  const [form] = useForm();
  const [menuOpen, setOpenMenu] = useState();
  const [extraData, setExtraData] = useState(null);
  const { isAdmin, roleId } = useAccount();
  const isMobile = useDeviceDetector();
  let heads = [
    {
      title: t("payoutPublisher.invoice"),
      key: "publisher_invoice_id",
      sort: true,
    },
    {
      title: t("payoutPublisher.publisherName"),
      key: "publisherName",
      sort: true,
      width: 150,
    },
    {
      title: t("users.UserType"),
      key: "type",
      sort: true,
      width: 100,
    },
    {
      title: t("payoutPublisher.nationalCode"),
      key: "national_code",
      width: 100,
    },
    {
      title: t("payoutPublisher.contract_status"),
      key: "contract_status",
      sort: true,
    },
    {
      title: t("payoutPublisher.status"),
      key: "status",
      sort: true,
    },
    {
      title: t("payoutPublisher.payment_status"),
      key: "payment_status",
      sort: true,
    },
    {
      title: t("payoutPublisher.create_date"),
      key: "create_date",
      width: 150,
      sort: true,
    },
    {
      title: t("payoutPublisher.payment_date"),
      key: "payment_date",
      width: 150,
      sort: true,
    },
    {
      title: t("invoice.cal_du_to"),
      key: "cal_du_to",
      width: 150,
      sort: true,
    },
    {
      title: t("payoutPublisher.sheba"),
      key: "sheba",
    },
    {
      title: t("invoice.commission"),
      key: "amount_commission",
      sort: true,
    },
    {
      title: t("invoice.amount_vat"),
      key: "amount_vat",
      sort: true,
    },
    {
      title: t("payoutPublisher.total"),
      key: "total",
      width: "140px",
      sort: true,
    },
  ];
  if (!isAdmin) {
    heads = heads.filter((item) => item.key !== "publisherName");
  }

  useEffect(() => {
    isPayoutPage
      ? dispatch(getPublisherInvoicesPayout({}))
      : dispatch(getPublisherInvoices({}));
  }, []);

  const itemsList = useMemo(() => {
    let items_ = _.get(invoicesPublisher, "data.result", []);
    items_ = items_?.map((item) => {
      let onEditInvoice = "";
      if (isAdmin) {
        onEditInvoice = () =>
          setOpenMenu({
            title: t("payoutPublisher.setStatus"),
            type: "SET_STATUS",
            payout: item,
          });
      }

      const status = (
        <TagCustom
          onClick={onEditInvoice}
          status={objectR(InvoiceStatus)[item.status]}
        >
          {t(`invoice.status_.${objectR(InvoiceStatus)[item.status]}`)}
        </TagCustom>
      );

      let onEditPaymentStatus = "";
      if (isAdmin) {
        onEditPaymentStatus = () =>
          setOpenMenu({
            title: t("payoutPublisher.setPaymentStatus"),
            type: "SET_PAYOUT_STATUS",
            payout: item,
          });
      }

      const translatedPaymentStatus = (
        <TagCustom
          onClick={onEditPaymentStatus}
          status={objectR(PaymentStatus)[item.payment_status]}
        >
          {t(
            `invoice.pay_status_.${objectR(PaymentStatus)[item.payment_status]}`
          )}
        </TagCustom>
      );

      const contractStatus =
        objectR(CONTRACT_STATUS)[item.publisher.user.contract_status];

      let publisher_name = `${item.publisher?.first_name} ${item.publisher?.last_name}`;
      if (item.publisher?.legal_status === LEGAL_STATUS.LEGAL) {
        publisher_name = item.publisher?.company_name;
      }

      let nationalCode = "";
      if (item.publisher?.legal_status === LEGAL_STATUS.LEGAL) {
        nationalCode = item.company_legal_info?.national_id;
      } else nationalCode = item?.publisher?.national_code;

      const cal_du_to = dateFormat(item?.projection?.end_date, "ST");

      return {
        ...item,
        create_date: dateFormat(item.create_date, "ST"),
        publisherName: publisher_name,
        type: t(`users.${objectR(LEGAL_STATUS)[item.publisher?.legal_status]}`),
        national_code: nationalCode || "-",
        publisher_invoice_id: (
          <Link
            to={"/invoices/" + item.publisher_invoice_id + "/p"}
            target="_blank"
          >
            #{item?.publisher_invoice_id}
          </Link>
        ),
        contract_status: contractStatus ? (
          <TagCustom status={contractStatus}>
            {t(`profile.contract.${contractStatus}`)}
          </TagCustom>
        ) : (
          "-"
        ),
        status: status,
        sheba: item.publisher?.sheba,
        payment_status: translatedPaymentStatus,
        total: item.total.display_amount,
        payment_date:
          MANUAL_CONFIG.SHOW_PAYMENT_DATE || roleId !== USER_TYPE.PUBLISHER
            ? dateFormat(item?.projection?.payment_date, "ST")
            : "-",
        amount_commission: priceFormat(item.commission) || "-",
        amount_vat: priceFormat(item.vat) || "-",
        cal_du_to,
      };
    });
    return items_;
  }, [invoicesPublisher]);

  const items = _.get(invoicesPublisher, "data.total_items", 10);
  const pages = _.get(invoicesPublisher, "data.total_pages", 1);
  const pageSize = _.get(invoicesPublisher, "data.page_size", 10);
  const page = _.get(invoicesPublisher, "data.page", 1);
  const tableInfo = { items, pages, pageSize, serverPagination: true, page };

  const onFinishSearch = async (ev) => {
    const filters = {};
    const e = getFinalFilters(extraData, ev, {});

    if (e.payment_date) {
      const fromDate = serverAcceptableDate(e.payment_date[0]);
      const toDate = serverAcceptableDate(e.payment_date[1]);
      filters["from_payment_date"] = fromDate;
      filters["to_payment_date"] = toDate;
    }
    if (e.create_date) {
      const fromDate = serverAcceptableDate(e.create_date[0]);
      const toDate = serverAcceptableDate(e.create_date[1]);
      filters["from_create_date"] = fromDate;
      filters["to_create_date"] = toDate;
    }

    const filter = {
      publisher_invoice_id: e.id,
      status: e.status_invoice,
      payment_status: e.status_payment,
      publisher_id: e.publisher,
      ...filters,
    };
    const params = getLastParams(filter, e);

    const resp = await dispatch(getPublisherInvoices(params));
    setExtraData(e);
    if (!resp?.payload?.success) {
      message.error({
        key: "ERROR-SEARCH-BAR",
        content: resp?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const onClearFilters = () => {
    setExtraData(null);
    if (isPayoutPage) {
      dispatch(getPublisherInvoicesPayout({}));
    } else {
      dispatch(getPublisherInvoices({}));
    }
    form.resetFields();
  };

  const handlePagination = (e) => {
    onFinishSearch({
      ...e,
    });
  };

  const handleSort = (e) => {
    onFinishSearch({
      page: 1,
      sort_field: e.order ? e.columnKey : undefined,
      sort_orientation: e.order ? (e.order === "ascend" ? 2 : 1) : undefined,
    });
  };

  return (
    <div>
      {isPayoutPage ? <PageTitle title={"PayoutsPage"} /> : ""}

      <MainContainer wrapDisabled={!isPayoutPage}>
        <>
          <FormSearchBar
            name="formSearch"
            form_={form}
            more
            onClearFilters={onClearFilters}
            onFinish={onFinishSearch}
            AdvanceFields={
              <>
                <Col md={8} xs={24}>
                  <Form.Item name={"create_date"}>
                    <InputRangePicker
                      placeholder={[
                        t("invoice.create_date_start"),
                        t("invoice.create_date_end"),
                      ]}
                    />
                  </Form.Item>
                </Col>
              </>
            }
          >
            <Col md={5} xs={24}>
              <Form.Item name={"id"}>
                <Input allowClear placeholder={t("invoice.id_search")} />
              </Form.Item>
            </Col>

            <Col md={5} xs={24}>
              <Form.Item name={"status_invoice"}>
                <Select allowClear placeholder={t("invoice.status_invoice")}>
                  {_.keys(InvoiceStatus).map((key) => (
                    <Select.Option key={key} value={InvoiceStatus[key]}>
                      {t(`invoice.status_.${key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={5} xs={24}>
              <Form.Item name={"status_payment"}>
                <Select allowClear placeholder={t("invoice.status_payment")}>
                  {_.keys(PaymentStatus).map((key) => (
                    <Select.Option key={key} value={PaymentStatus[key]}>
                      {t(`invoice.pay_status_.${key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {isAdmin ? (
              <Col md={5} xs={24}>
                <SelectPublisher name="publisher" />
              </Col>
            ) : (
              ""
            )}
            <Col md={9} xs={24}>
              <Form.Item name={"payment_date"}>
                <InputRangePicker
                  placeholder={[
                    t("invoice.payment_date_start"),
                    t("invoice.payment_date_end"),
                  ]}
                />
              </Form.Item>
            </Col>
          </FormSearchBar>

          <TableComponent
            loading={invoicesPublisherLoading}
            heads={heads}
            data={itemsList}
            info={tableInfo}
            handlePagination={handlePagination}
            handleSort={handleSort}
            scroll={{ x: isMobile ? "100vw" : 2000 }}
          />
        </>
      </MainContainer>

      <SliderMenu
        title={menuOpen?.title}
        collapsed={menuOpen}
        setCollapsed={setOpenMenu}
      >
        {menuOpen?.type === "SET_PAYOUT_STATUS" && (
          <FormPayoutStatusPublisher
            data={menuOpen}
            close={() => setOpenMenu()}
            onFinishSearch={onFinishSearch}
          />
        )}
        {menuOpen?.type === "SET_STATUS" && (
          <FormStatusPublisher
            data={menuOpen}
            close={() => setOpenMenu()}
            onFinishSearch={onFinishSearch}
          />
        )}
      </SliderMenu>
    </div>
  );
};

PayoutPublisherList.propTypes = {
  setFilters: PropTypes.isFunction,
};
export default PayoutPublisherList;
