import {
  FileExcelOutlined as ExcelIcon,
  PrinterOutlined as PrintIcon,
} from "@ant-design/icons";
import { Button, Tabs, message } from "antd";
import { t } from "i18next";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";

import { getPublisherInvoicesExport } from "@/app/local/invoiceSlice";
import MainContainer from "@/components/container/MainContainer";
import PayoutPublisherList from "@/components/finance/PayoutPublisherList";
import PageTitle from "@/components/general/PageTitle";
import { CSV_MIME_TYPE } from "@/utils/constants/configConstants";
import { getTimeStamp, saveFile } from "@/utils/helper";

import ProjectionsPublisher from "../projection/ProjectionsPublisher";
import "./styles.less";

const { TabPane } = Tabs;

const FinancePublisher = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("projections");
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});

  const onChange = (e) => {
    setTab(e);
    navigate(
      {
        search: createSearchParams({
          tab: e || "",
        }).toString(),
      },
      { replace: true }
    );
  };

  const onPrint = () => {
    window.open("/finance_report", "_blank");
  };

  const onExcel = async () => {
    message.loading({
      content: t("general.pleaseWaitMessage2"),
      duration: 2,
      key: "k1",
    });
    const filters_ = _.assign(filters, {
      page: 1,
      page_size: 700,
    });
    const result = await dispatch(getPublisherInvoicesExport(filters_));
    if (result.payload.success) {
      saveFile({
        fileName: `invoices-export-${getTimeStamp()}`,
        b64: result.payload.data,
        mType: CSV_MIME_TYPE,
      });
      message.destroy("k1");
    } else {
      message.error({
        content: t("general.UnexpectedErrorOccurred"),
        duration: 2,
        key: "k1",
      });
    }
  };

  return (
    <div className="finance">
      <PageTitle title={"FinancePublisher"} />
      <MainContainer>
        <>
          <Tabs defaultActiveKey={tab} onChange={onChange}>
            <TabPane tab={t("finance.projections")} key="projections">
              <ProjectionsPublisher />
            </TabPane>

            <TabPane tab={t("finance.payouts")} key="payouts">
              <div className="finance__left">
                <Button
                  type="default"
                  icon={<ExcelIcon />}
                  // disabled
                  onClick={onExcel}
                >
                  {t("factor.excel")}
                </Button>
                <Button type="primary" icon={<PrintIcon />} onClick={onPrint}>
                  {t("factor.print")}
                </Button>
              </div>
              <PayoutPublisherList setFilters={setFilters} />
            </TabPane>
          </Tabs>
        </>
      </MainContainer>
    </div>
  );
};

export default FinancePublisher;
