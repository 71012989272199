import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ENDPOINTS } from "@/utils/constants/apiConstants";
import { clearObject } from "@/utils/utils";

import { baseAPI } from "../services/fetchers";

export const getLinksListAsync = createAsyncThunk(
  "link/getLinksListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        sort_orientation: "1",
      };
      const data_ = clearObject(data);
      const body_ = Object.assign({}, body, data_);
      const end_ = ENDPOINTS.LINK_LIST;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLinksGroupListAsync = createAsyncThunk(
  "link/getLinksGroupListAsync",
  async (data) => {
    try {
      const body = {
        page: 1,
        page_size: 10,
        // sort_field: "string",
        // search_text: "string",
        is_pagination: true,
        sort_orientation: 1,
      };
      const body_ = Object.assign({}, body, data?.body);
      const end_ = ENDPOINTS.LINK_LIST_GROUP;
      const response = await baseAPI({
        endPoint: end_,
        body: body_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const addLinkAsync = createAsyncThunk(
  "link/addLinkAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.LINK_ADD;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const editLinkAsync = createAsyncThunk(
  "link/editLinkAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.LINK_EDIT;
      const response = await baseAPI({
        endPoint: end_,
        body: data,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const deleteLinkAsync = createAsyncThunk(
  "link/deleteLinkAsync",
  async (lkId) => {
    try {
      const end_ = ENDPOINTS.LINK_DELETE.replace("{linkId}", lkId);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const changeStateLinkAsync = createAsyncThunk(
  "link/changeStateLinkAsync",
  async (data) => {
    try {
      const end_ = ENDPOINTS.LINK_STATE.replace("{linkId}", data.id).replace(
        "{status}",
        data.status
      );
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const getLinkDetailAsync = createAsyncThunk(
  "link/getLinkDetailAsync",
  async (id) => {
    try {
      const end_ = ENDPOINTS.LINK_DETAIL.replace("{linkId}", id);
      const response = await baseAPI({
        endPoint: end_,
      });

      // The value we return becomes the `fulfilled` action payload
      return { ...response.data };
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const exportLinksCsvAsync = createAsyncThunk(
  "link/exportLinksCsvAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.LINK_LIST_EXCEL;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const exportLinksCsvAsyncTypeAsync = createAsyncThunk(
  "order/exportLinksCsvAsyncTypeAsync",
  async (data) => {
    try {
      let end_ = ENDPOINTS.LINK_LIST_EXCEL_ASYNC;
      const response = await baseAPI({
        endPoint: end_,
        body: data || {},
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }
);

export const linkSlice = createSlice({
  name: "link",
  initialState: {
    links: null,
    linksLoading: false,
    linksError: null,

    addLinkLoading: false,
    addLinkError: null,
    addLinkData: null,

    linkLoading: false,
    linkError: null,
    linkData: null,

    linkGroupListLoading: false,
    linkGroupError: null,
    linkGroupData: null,

    loading: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.addLinkLoading = false;
      state.addLinkError = false;
      state.addLinkData = null;

      state.linkLoading = false;
      state.linkError = null;
      state.linkData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLinksListAsync.pending, (state, action) => {
      state.linksLoading = true;
      state.linksError = null;
    });
    builder.addCase(getLinksListAsync.rejected, (state, action) => {
      state.linksLoading = false;
      state.linksError = action.error;
    });
    builder.addCase(getLinksListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.links = action.payload;
      }
      state.linksLoading = false;
      state.linksError = null;
    });

    builder.addCase(addLinkAsync.pending, (state, action) => {
      state.addLinkLoading = true;
      state.addLinkError = null;
    });
    builder.addCase(addLinkAsync.rejected, (state, action) => {
      state.addLinkLoading = false;
      state.addLinkError = action.error;
    });
    builder.addCase(addLinkAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.addLinkData = action.payload;
      }
      state.addLinkLoading = false;
      state.addLinkError = null;
    });

    builder.addCase(getLinkDetailAsync.pending, (state, action) => {
      state.linkLoading = true;
      state.linkError = null;
    });
    builder.addCase(getLinkDetailAsync.rejected, (state, action) => {
      state.linkLoading = false;
      state.linkError = action.error;
    });
    builder.addCase(getLinkDetailAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.linkData = action.payload;
      }
      state.linkLoading = false;
      state.linkError = null;
    });

    builder.addCase(getLinksGroupListAsync.pending, (state, action) => {
      state.linkGroupListLoading = true;
      state.linkGroupError = null;
    });
    builder.addCase(getLinksGroupListAsync.rejected, (state, action) => {
      state.linkGroupListLoading = false;
      state.linkGroupError = action.error;
    });
    builder.addCase(getLinksGroupListAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.linkGroupData = action.payload;
      }
      state.linkGroupListLoading = false;
      state.linkGroupError = null;
    });

    builder.addCase(changeStateLinkAsync.pending, (state, action) => {});
    builder.addCase(changeStateLinkAsync.rejected, (state, action) => {});
    builder.addCase(changeStateLinkAsync.fulfilled, (state, action) => {
      if (action.payload.success) {
        try {
          const linkId = action.meta.arg.id;
          const newStatus = action.meta.arg.status;

          state.links.data.result = state.links.data.result.map((item) =>
            item.link_id === linkId ? { ...item, is_enabled: newStatus } : item
          );
        } catch (e) {
          const cashing = "";
        }
      }
    });
  },
});

export const getLinksList = (data) => async (dispatch) => {
  return dispatch(getLinksListAsync(data));
};

export const getLinksGroupList = (data) => async (dispatch) => {
  return dispatch(getLinksGroupListAsync(data));
};

export const addLink = (data) => async (dispatch) => {
  return dispatch(addLinkAsync(data));
};

export const editLink = (data) => async (dispatch) => {
  return dispatch(editLinkAsync(data));
};

export const deleteLink = (data) => async (dispatch) => {
  return dispatch(deleteLinkAsync(data));
};

export const changeStateLink = (data) => async (dispatch) => {
  return dispatch(changeStateLinkAsync(data));
};

export const getLinkDetail = (data) => async (dispatch) => {
  return dispatch(getLinkDetailAsync(data));
};

export const { clearState } = linkSlice.actions;

export default linkSlice.reducer;
