import {
  DatePicker as DatePickerJalali,
  JalaliLocaleListener,
} from "antd-jalali-plus";
import React from "react";

const InputRangePicker = (props) => {
  return (
    <>
      <JalaliLocaleListener />
      <DatePickerJalali.RangePicker {...props} showToday />
    </>
  );
};

export default InputRangePicker;
