import { Bar } from "@ant-design/plots";
import { Col, Row } from "antd";
import { t } from "i18next";
import { truncate } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTop10Categories } from "@/app/local/reportSlice";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { kFormatter, numberFormat } from "@/utils/helper";

import NameTag from "../micro/NameTag";
import { ChartHolder } from "./AdminViewClickChart";
import { COLORS } from "./colors";

const TopCategories = (props) => {
  const { title, pieChart, type: typeMain } = props;
  const dispatch = useDispatch();
  const [context, dispatch_] = useContext(DashboardContext);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState({});

  const { top10Categories, top10CategoriesLoading } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    dispatch(
      getTop10Categories({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (top10Categories) {
      let temp = [...(top10Categories?.data || [])];
      let dataItems = [];
      const tempTotal = { generatedOrder: 0, finalizedOrder: 0 };

      temp = temp.sort((a, b) => {
        const h =
          b.finialized_order_commission -
          a.finialized_order_commission +
          (b.generated_order_commission - a.generated_order_commission);
        return h;
      });
      temp.map((item) => {
        let name = truncate(item.name, { length: 20, omission: "" });
        dataItems.push({
          name: name,
          value: item.finialized_order_commission,
          type: "dashboard.finalizedOrders",
        });
        tempTotal.finalizedOrder += item.finialized_order_commission;

        dataItems.push({
          name: name,
          value: item.generated_order_commission,
          type: "dashboard.generatedOrder",
        });
        tempTotal.generatedOrder += item.generated_order_commission;
      });

      setTotal(tempTotal);
      setData(dataItems);
    }
  }, [top10Categories]);

  const chartConfig = {
    data: data,
    isStack: true,
    padding: [20, 20, 20, 160],
    appendPadding: 8,
    xField: "value",
    yField: "name",
    renderer: "svg",
    legend: false,
    maxBarWidth: 15,
    seriesField: "type",
    theme: {
      styleSheet: {
        fontFamily: "IRANYekan",
      },
    },
    xAxis: {
      label: {
        formatter: (v) => kFormatter(v),
      },
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: `${t(datum.type)}(${t("general.currency")})`,
          value: numberFormat(datum.value),
        };
      },
    },
    color: ({ type, year }, ...x) => {
      switch (type) {
        case "dashboard.finalizedOrders":
          return typeMain !== "FINALIZED_ORDER"
            ? COLORS.DASHBOARD_GREEN
            : COLORS.DASHBOARD_BLUE;
        case "dashboard.generatedOrder":
          return typeMain !== "FINALIZED_ORDER"
            ? COLORS.DASHBOARD_ORANGE
            : COLORS.DASHBOARD_RED;
        default:
          return COLORS.DASHBOARD_BLUE;
      }
    },
  };

  return (
    <>
      <Row>
        <Col md={24} xs={24}>
          <div className={`tags-bar align__right`}>
            <NameTag
              title={t("dashboard.generatedOrder")}
              color={typeMain === "FINALIZED_ORDER" ? "color_1" : "color_3"}
            />
            <NameTag
              title={t("dashboard.finalizedOrders")}
              color={typeMain === "FINALIZED_ORDER" ? "color_6" : "color_5"}
            />
          </div>
          <ChartHolder
            loading={top10CategoriesLoading}
            len={data?.length}
            height="400px"
          >
            <div dir="ltr" style={{ direction: "ltr" }}>
              <Bar {...chartConfig} loading={top10CategoriesLoading} />
            </div>
          </ChartHolder>
        </Col>
      </Row>
    </>
  );
};

TopCategories.propTypes = {
  title: PropTypes.string,
  pieChart: PropTypes.bool,
  type: PropTypes.bool,
};
export default TopCategories;
