import { Button, Col, Form, Input, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { accountChangePassword } from "@/app/local/accountSlice";
import { adminChangePassword } from "@/app/local/adminUserSlice";
import { isUserPage } from "@/pages/profile";
import { MASK_MOBILE_INPUT } from "@/utils/constants/configConstants";
import { useAccount } from "@/utils/hooks/useAuth";

import InputPassword from "../input/inputPassword";
import InputPasswordConfirm from "../input/inputPasswordConfirm";
import MaskInput from "../input/maskInput";

const FormUpdatePassword = (props) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isAdmin, user } = useAccount();
  const { userType, userInfo } = props;
  const location = useLocation();
  const page = location.pathname;

  const isAdminPage = page.includes("/administrators");

  const onFinish = async (e) => {
    if (!isAdmin) {
      setLoading(true);
      const res = await dispatch(
        accountChangePassword({
          old_password: e.oldPassword,
          new_password: e.password,
          confirm_new_password: e.passwordConfirm,
        })
      );

      if (res.payload?.success) {
        message.success({
          content: t("general.saveSuccessFully"),
          key: "user",
        });
      } else {
        message.error({
          content: res.payload?.Message || t("general.unHandleErrorMessage"),
          key: "user",
        });
      }
      setLoading(false);
    } else {
      setLoading(true);
      const res = await dispatch(
        adminChangePassword({
          mobile: isAdminPage
            ? e?.mobile?.trim()
            : user?.mobile || userInfo?.mobile,
          new_password: e.password,
          confirm_new_password: e.passwordConfirm,
        })
      );

      if (res.payload?.success) {
        message.success({
          content: t("general.saveSuccessFully"),
          key: "user",
        });
        form.resetFields();
      } else {
        message.error({
          content: res.payload?.Message || t("general.unHandleErrorMessage"),
          key: "user",
        });
      }
      setLoading(false);
    }
  };

  let isSettingPage;
  if (page === "/setting") {
    isSettingPage = true;
  } else isSettingPage = false;

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Row>
        <Col md={24}>
          <br />
          <Title level={5}>{t("user.changePassword")}</Title>
          <br />
        </Col>
        <Col md={12} xs={24}>
          {isAdmin && !isSettingPage && isAdminPage ? (
            <Form.Item
              name="mobile"
              style={{ direction: "rtl" }}
              label={t("user.mobile")}
            >
              <MaskInput
                // prefix="0"
                mask={MASK_MOBILE_INPUT}
                placeholder={t("auth.phone")}
                style={{ textAlign: "right", direction: "ltr" }}
                placeholderChar="-"
                size="large"
              />
            </Form.Item>
          ) : (
            ""
          )}

          {(isAdmin && isSettingPage) ||
          !isUserPage ||
          (!isAdmin && isUserPage) ? (
            <Form.Item
              name="oldPassword"
              className="auth__password"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.Password size="large" placeholder={t("auth.password")} />
            </Form.Item>
          ) : (
            ""
          )}

          <InputPassword placeholder="auth.passwordNew" />
          <InputPasswordConfirm placeholder="auth.passwordConfirmNew" />

          <Form.Item
            wrapperCol={{
              // offset: 8,
              span: 16,
            }}
            className="form_actions"
          >
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              {t("general.save")}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

FormUpdatePassword.propTypes = {
  userInfo: PropTypes.object,
  userType: PropTypes.number,
  isUpdateUser: PropTypes.bool,
};

export default FormUpdatePassword;
