import { Button, Form, Modal, Space, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useDispatch } from "react-redux";

import { controlPanelGetBody } from "@/app/local/controlPanelSlice";
import { callApi } from "@/app/local/publisherSlice";
import { getProfileData } from "@/pages/profile";
import { getUrl } from "@/utils/helper";

import InputUrl from "../input/inputUrl";

const ServiceSetting = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const profile = getProfileData();
  const [body, setBody] = useState();
  const userInfo = _.get(profile, "userDetail");

  useEffect(() => {
    const req = dispatch(controlPanelGetBody());
    req.then((r) => {
      if (r?.payload?.data) {
        setBody(JSON.parse(r?.payload?.data));
      }
    });
    if (userInfo?.new_order_notice_call_back) {
      const { type, url } = getUrl(userInfo.new_order_notice_call_back);
      form.setFieldsValue({ url_link: url, type_link: type });
    }
  }, [userInfo]);

  const onFinish = async (e) => {
    const url = e.url_type + e.url_link;
    const result = await dispatch(
      callApi({ publisher_id: 0, callback_url: url })
    );
    if (result?.payload?.success) {
      message.success({
        content: t("general.saveSuccessFully"),
      });
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };
  const onClickTestApi = () => {
    try {
      const { url_type, url_link } = form.getFieldsValue();
      const url = url_type + url_link;
      const body_ = { ...body };
      body_.OrderItems = [_.get(body, "OrderItems[0]")];
      fetch(url, {
        method: "post",
        body: JSON.stringify(body_),
      })
        .then((response) => response.json())
        .then((r) => {
          Modal.info({
            title: t("profile.responseTitle"),
            content: (
              <div style={{ direction: "ltr" }}>{JSON.stringify(r)}</div>
            ),
            okText: t("general.close"),
          });
        })
        .catch((e) => {
          message.error({
            content: t("general.UnexpectedErrorOccurred"),
          });
        });
    } catch (e) {
      message.error({
        content: t("general.UnexpectedErrorOccurred"),
      });
    }
  };

  return (
    <div className="service-setting">
      <p>{t("profile.serviceSettingText")}:</p>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ url_type: "https://" }}
      >
        <InputUrl
          size="large"
          name={"url"}
          placeholder={t("profile.enterUrl")}
        />

        <Form.Item label={t("profile.dataModel")}>
          <div className="code-area">
            <ReactJson src={body} />
          </div>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              {t("profile.saveUrl")}
            </Button>
            <Button type="default" onClick={onClickTestApi}>
              {t("profile.testApi")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ServiceSetting;
