// import { updateUser } from "app/local/user";
// import { PRIVATE_BASE_PATH } from "app/routes";
// import { useLoginMutation } from "app/services/mock";
import { Button, Checkbox, Form, message } from "antd";
import Title from "antd/lib/typography/Title";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  accountRegister,
  accountSendCodeUnRegistered,
  accountVerify,
  clearState,
} from "@/app/local/accountSlice";
import LogoImage from "@/assets/images/logo-white.svg";
import RegisterImage from "@/assets/images/register.svg";
import inTrackEvent from "@/components/extra/Intrack/InTrackEvent";
import CodeInput from "@/components/input/codeInput";
import InputPassword from "@/components/input/inputPassword";
import InputPasswordConfirm from "@/components/input/inputPasswordConfirm";
import MaskInput from "@/components/input/maskInput";
import ModalContentTerms from "@/components/micro/ModalContentTerms";
import SelectUserType from "@/components/userInfo/SelectUserType";
import { SEND_CODE_CONSUMER } from "@/utils/constants/apiConstants";
import { MASK_MOBILE_INPUT } from "@/utils/constants/configConstants";
import { getLastError } from "@/utils/helper";
import useConfig from "@/utils/hooks/useConfig";

// import { useAuth } from "@/utils/hooks/useAuth";
import AuthLayout from "./authLayout";

const Mode = {
  REGISTER: "REGISTER",
  VERIFY: "VERIFY",
};

export const Register = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "ssp"]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [mode, setMode] = useState(props.mode || Mode.VERIFY);
  const [userType, setUserType] = useState();
  const { register, verify, error, loading } = useSelector(
    (state) => state.account
  );
  const { isSSP } = useConfig();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleModal = (isAgree = false) => {
    if (isAgree) {
      form.setFieldsValue({ agreement: true });
    }
    setIsModalVisible((old) => !old);
  };

  useEffect(() => {
    if (register) {
      if (register?.success) {
        message.success({
          content: t("auth.successRegisterMessage"),
          key: "login",
          duration: 2,
        });
        // autoLogin
        setTimeout(() => navigateTo("/login"), 1000);
      }
    } else {
      setMode(props.mode || Mode.REGISTER);
    }
  }, [register]);

  useEffect(() => {
    if (!props.from && error) {
      const errorMessage = error?.Message || getLastError(error);
      message.error({
        content: errorMessage,
        key: "login",
      });
    }
  }, [error]);

  const onFinish = async (e) => {
    try {
      const result = await onSendCode();
      if (result?.payload?.success) {
        setMode(Mode.VERIFY);
      }
    } catch (err) {
      const catching = "catch";
    }
  };

  const onSendCode = async (e) => {
    try {
      console.log("1");
      clarity.setTag("register", "fill-phone-password-register-event");
      console.log("2");
      const phone = form.getFieldValue("mobile").replaceAll(" ", "").trim();
      const eventParam = {
        phone,
        attributes: {
          kycStatus: 1,
        },
      };
      inTrackEvent.updateProfile(eventParam);
      return await dispatch(
        accountSendCodeUnRegistered({
          username: phone,
          consumer: SEND_CODE_CONSUMER.REGISTER,
        })
      );
    } catch (err) {
      const catching = "catch";
    }
  };

  const onFinishVerify = async (e) => {
    clarity.setTag("register", "otp-register-event");
    if (props.from) {
      const phone = props.phone.replaceAll(" ", "").trim();
      const eventParam = {
        phone,
        attributes: {
          kycStatus: 2,
        },
      };
      inTrackEvent.updateProfile(eventParam);
      const verifyPhone = await dispatch(
        accountVerify({
          token: e.code,
          username: phone,
          consumer: SEND_CODE_CONSUMER.FORGOT_PASSWORD,
        })
      );
      if (verifyPhone.payload?.success) {
        props.setCode(e.code);
        props.setMode("CHANGE_PASSWORD");
      }
    } else {
      try {
        const phone = form.getFieldValue("mobile").replaceAll(" ", "").trim();
        const password = form.getFieldValue("password");
        const passwordConfirm = form.getFieldValue("passwordConfirm");
        const agreement = form.getFieldValue("agreement");
        const userType = form.getFieldValue("userType");

        dispatch(
          accountRegister({
            token: e.code,
            mobile: phone,
            password: password,
            confirm_password: passwordConfirm,
            role_type: userType,
            agreement: agreement,
          })
        );
      } catch (err) {
        const catching = "catch";
      }
    }
  };

  const openAgreement = (e) => {
    e.preventDefault();
    handleModal();
  };

  const getPhone = () => {
    return (props.phone || form.getFieldValue("mobile"))?.replaceAll(" ", "-");
  };

  const navigateTo = (name) => {
    dispatch(clearState());
    navigate(name);
  };

  const onValuesChange = (e) => {
    if (e.userType) {
      setUserType(e.userType);
    }
  };

  return (
    <AuthLayout>
      <>
        <div className="auth__image">
          <div>
            <div className="image__bg">
              <img src={RegisterImage} />
            </div>

            {isSSP ? (
              <div className="image__info">
                <h2 style={{ fontWeight: "bold", color: "#fff" }}>
                  ADVERGE SSP
                </h2>
                <p>{t("auth.registerText", { ns: "ssp" })}</p>
              </div>
            ) : (
              <div className="image__info">
                <img src={LogoImage} className="logo" />
                <p>{t("auth.registerText")}</p>
              </div>
            )}
          </div>
        </div>
        {mode === Mode.REGISTER ? (
          <Form
            form={form}
            className="auth__form"
            name="basic"
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onValuesChange={onValuesChange}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Title level={4} className={"auth__title"}>
              {t("auth.register")}
            </Title>

            <Form.Item name="userType" noStyle>
              <SelectUserType form={form} />
            </Form.Item>

            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  message: t("required"),
                },
              ]}
              style={{ direction: "ltr" }}
            >
              <MaskInput
                // prefix="0"
                mask={MASK_MOBILE_INPUT}
                placeholder={t("auth.phone")}
                size="large"
                className="direction"
                placeholderChar="-"
              />
            </Form.Item>

            <InputPassword />

            <InputPasswordConfirm />

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(t("auth.shouldAcceptAgreement"))
                        ),
                },
              ]}
              wrapperCol={{
                // offset: 8,
                span: 24,
              }}
            >
              <Checkbox>
                {t("auth.termConditionFirst")}{" "}
                {<a onClick={openAgreement}>{t("auth.showAgreement")}</a>}{" "}
                {t("auth.termConditionSecond")}
              </Checkbox>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 16,
              }}
              className="auth__actions"
            >
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
                // disabled={isSSP}
              >
                {t("auth.register")}
              </Button>
              <span className="space-or"></span>
              <Button
                size="large"
                type="info"
                onClick={() => navigateTo("/login")}
              >
                {t("auth.login")}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          ""
        )}

        {mode === Mode.VERIFY ? (
          <Form
            form={form2}
            onFinish={onFinishVerify}
            className="auth__form"
            name="basic"
          >
            <Title level={4} className={"auth__title"}>
              {t("auth.verify")}
            </Title>
            <p>
              {t("auth.pleaseEnterFirst")}
              <b className="auth__phone">{getPhone()}</b>
            </p>
            <a
              onClick={() =>
                !props.from ? setMode(Mode.REGISTER) : props.setMode(props.from)
              }
            >
              {t("auth.changeMobileNumber")}
            </a>
            <br />
            <br />
            <div style={{ direction: "ltr" }}>
              <Form.Item noStyle name="code" rules={[{ required: true }]}>
                <CodeInput form={form2} sendCode={onSendCode} />
              </Form.Item>
            </div>
            <br />

            <Form.Item
              wrapperCol={{
                // offset: 8,
                span: 16,
              }}
              className="auth__actions"
            >
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("auth.accept")}
              </Button>
              <span className="space-or"></span>
              {/* <Button
              size="large"
              type="dashed"
              onClick={() => setMode(Mode.REGISTER)}
            >
              برگشت
            </Button> */}
            </Form.Item>
          </Form>
        ) : (
          ""
        )}
        {isModalVisible ? (
          <ModalContentTerms
            isModalVisible={isModalVisible}
            handleModal={handleModal}
            userType={userType}
          />
        ) : (
          ""
        )}
      </>
    </AuthLayout>
  );
};

Register.propTypes = {
  mode: Mode,
  from: PropTypes.string,
  phone: PropTypes.string,
  setMode: PropTypes.func,
  setCode: PropTypes.func,
};

export default Register;
