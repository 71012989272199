import {
  BookOutlined,
  DesktopOutlined,
  DollarCircleOutlined,
  LinkOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import React, { lazy } from "react";

import {
  IconDashboard2x,
  IconFinance,
  IconInvoice2,
  IconMonitor,
  IconPlaces,
  IconProfile,
  IconWallet2,
  IconWebsites,
} from "@/assets/icon-pack";
import SidebarIcon from "@/assets/images/sidebar";
import Dashboard from "@/pages/dashboard";
import FinancePublisher from "@/pages/finance/FinancePublisher";
import InvoiceView from "@/pages/invoice/InvoiceView";
import InvoicesPublisher from "@/pages/invoice/InvoicesPublisher";
import Places from "@/pages/places/places";
import ForgotPassword from "@/pages/public/forgot-password";
import LoginPage from "@/pages/public/login-ssp";
import RegisterPage from "@/pages/public/register";
import ResetPassword from "@/pages/public/resetPassword";
import PlaceReport from "@/ssp/placeReport/PlaceReport";
import { Privileges } from "@/utils/constants/accessCenter";
import { USER_TYPE } from "@/utils/constants/apiConstants";
import { MANUAL_CONFIG } from "@/utils/constants/configConstants";

// import DashboardSSPAdvertiser from "@/ssp/dashboard/DashboardSSPAdvertiser";
const DashboardSSPAdvertiser = React.lazy(() =>
  import("@/ssp/dashboard/DashboardSSPAdvertiser")
);

const PayoutPublisherList = React.lazy(() =>
  import("@/components/finance/PayoutPublisherList")
);
const AdPlaceBuilder = React.lazy(() => import("@/ssp/AdPlaceBuilder"));
const Media = React.lazy(() => import("@/pages/media/media"));
const Orders = React.lazy(() => import("@/pages/order/orders"));
const Profile = React.lazy(() => import("@/pages/profile"));

const Setting = lazy(() => import("@/pages/setting/setting"));
const Websites = lazy(() => import("@/pages/website-ssp/websites"));
const Publishers = lazy(() => import("@/pages/user/list/publishers"));
const Admins = lazy(() => import("@/pages/user/list/admins"));
const ContractsUserList = React.lazy(() =>
  import("@/pages/contract/contractsUserList")
);

export const PRIVATE_BASE_PATH = "/dashboard";

export const PUBLIC_ROUTES = [
  {
    name: "HomePage",
    path: "/",
    exact: true,
    component: <LoginPage />,
  },
  {
    name: "Login",
    path: "/login",
    exact: true,
    component: <LoginPage />,
  },
  {
    name: "Register",
    path: "/register",
    exact: true,
    component: <RegisterPage />,
  },
  {
    name: "ForgotPassword",
    path: "/forgot",
    exact: true,
    component: <ForgotPassword />,
  },
  {
    name: "InvoiceView",
    path: "/invoice_view/:id/:type",
    // exact: true,
    secure: true,
    component: <InvoiceView />,
    restrict: [],
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    exact: true,
    secure: true,
    component: <ResetPassword />,
    restrict: [],
  },
];

const A = USER_TYPE.SUPER_ADMIN;
const P = USER_TYPE.PUBLISHER;
const M = USER_TYPE.MERCHANT;
const AD = USER_TYPE.ADVERTISER;
const isFaqActive = MANUAL_CONFIG.IS_FAQ_ACTIVE;

export const parentsIcon = {
  users: <IconProfile />,
  LinksManage: <LinkOutlined />,
  websitesManage: <DesktopOutlined />,
  Settings: <SettingOutlined />,
  Invoices: <DollarCircleOutlined />,
  Reports: <BookOutlined />,
};

export const AUTHENTICATED_ROUTES = [
  {
    key: "dashboard",
    path: "/",
    exact: true,
    component: <Dashboard />,
    title: "Analytics",
    // icon: <AppstoreOutlined />,
    icon: <IconDashboard2x />,
    options: {
      order: {
        admin: 1,
        merchant: 1,
        publisher: 1,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [
      Privileges.Dashboard.DASHBOARD_ADMIN,
      Privileges.Dashboard.DASHBOARD_MERCHANT,
      Privileges.Dashboard.DASHBOARD_PUBLISHER,
    ],
  },
  {
    path: "/places",
    component: <Places />,
    title: "AdPlaces",
    titlePage: "AdPlaces",
    // restrict: [],
    // icon: <i className="adt icon-gallery-outline"></i>,
    icon: <IconPlaces />,
    options: {
      order: {
        publisher: 1,
        admin: 4,
        merchant: 4,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [Privileges.Dashboard.DASHBOARD_PUBLISHER],
  },
  {
    path: "/places/new",
    component: <AdPlaceBuilder />,
    title: "PlaceNew",
    titlePage: "PlaceNew",
    restrict: [],
    parent: "places",
    menu: false,
    options: {
      order: {
        publisher: 3,
      },
    },
  },
  {
    path: "/places/:id",
    component: <AdPlaceBuilder />,
    title: "PlaceEdit",
    titlePage: "PlaceEdit",
    restrict: [],
    exact: true,
    parent: "places",
    menu: false,
    options: {
      order: {
        publisher: 3,
      },
    },
  },
  {
    path: "/places/:id/report",
    component: <PlaceReport />,
    title: "PlaceEdit",
    titlePage: "PlaceEdit",
    restrict: [],
    exact: true,
    parent: "places",
    menu: false,
    options: {
      order: {
        publisher: 3,
      },
    },
  },
  {
    path: "/websites",
    component: <Websites />,
    title: "MyWebsites",
    titlePage: "MyWebsites",
    restrict: [M, A],
    parent: "medias",
    icon: <IconMonitor />,
    options: {
      order: {
        publisher: 2,
        admin: 3,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [Privileges.MEDIA.MEDIA_MGM_VIEW],
  },
  {
    path: "/websites",
    component: <Websites />,
    title: "websitesSSP",
    titlePage: "websitesSSP",
    restrict: [M, AD],
    parent: "medias",
    icon: <IconWebsites />,
    options: {
      order: {
        publisher: 2,
        admin: 3,
      },
      subset: {
        publisher: "home",
      },
    },
    privileges: [Privileges.MEDIA.MEDIA_MGM_VIEW],
  },
  {
    path: "/website/:id",
    component: <Media />,
    title: "MediaEdit",
    titlePage: "MediaEdit",
    restrict: [M],
    parent: "/medias",
    menu: false,
  },
  {
    path: "/orders",
    component: <Orders />,
    title: "Orders",
    titlePage: "Orders",
    restrict: [],
    icon: <SidebarIcon type="orderIcon" />,
    menu: false,
    options: {
      order: {
        admin: 5,
        merchant: 5,
        publisher: 6,
      },
      subset: {
        publisher: "finance",
      },
    },
    privileges: [Privileges.ORDER.ORDER_VIEW],
  },
  {
    path: "/invoices-publisher",
    component: <InvoicesPublisher />,
    title: "InvoicesPublisher",
    titlePage: "InvoicesPublisher",
    icon: <IconFinance />,
    restrict: [P, M, AD],
    // parent: "Invoices",
    options: {
      order: {
        admin: 6,
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_PUBLISHER_INVOICE],
  },
  {
    key: "publishers",
    path: "/publishers",
    titlePage: "publishers",
    exact: true,
    component: <Publishers />,
    title: "Publishers",
    restrict: [P, M, AD],
    icon: <IconProfile />,
    // parent: "users",
    options: {
      order: {
        admin: 7,
      },
    },
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    path: "/administrators",
    component: <Admins />,
    title: "Administrators",
    titlePage: "Administrators",
    restrict: [P, M, AD],
    exact: true,
    parent: "users",
    icon: <IconProfile />,
    options: {
      order: {
        admin: 7,
      },
    },
    privileges: [Privileges.USER.USER_MGM_LIST],
  },
  {
    path: "/publishers/:id",
    component: <Profile />,
    title: "UserDetail",
    titlePage: "UserDetail",
    restrict: [P, M, AD],
    parent: "/publishers",
    menu: false,
  },
  {
    path: "/setting",
    exact: true,
    component: <Setting />,
    title: "SettingAccount",
    restrict: [M, P, AD],
    parent: "Settings",
    options: {
      order: {
        admin: 8,
      },
    },
    menu: false,
  },
  {
    path: "/payouts",
    component: <PayoutPublisherList />,
    title: "PayoutsPage",
    titlePage: "PayoutsPage",
    icon: <IconWallet2 />,
    restrict: [A, M],
    // parent: "Invoices",
    options: {
      order: {
        publisher: 7,
      },
      subset: {
        publisher: "finance",
      },
    },
  },
  {
    path: "/contracts/:id",
    component: <ContractsUserList />,
    title: "ContractsUserList",
    titlePage: "ContractsUserList",
    restrict: [],
    menu: false,
  },
  {
    path: "/contracts",
    component: <ContractsUserList />,
    title: "ContractsUserList",
    titlePage: "ContractsUserList",
    restrict: [P, M],
    parent: "Settings",
    menu: false,
  },
  {
    path: "/invoices",
    component: <InvoicesPublisher />,
    icon: <IconInvoice2 />,
    title: "InvoicesPage",
    titlePage: "InvoicesPage",
    restrict: [A, M],
    parent: "Invoices",
    options: {
      order: {
        merchant: 7,
      },
      subset: {
        publisher: "finance",
      },
    },
    privileges: [Privileges.FINANCE.FINANCE_PUBLISHER_INVOICE],
  },
  {
    path: "/finance-publisher",
    component: <FinancePublisher />,
    title: "FinancePublisher",
    titlePage: "FinancePublisher",
    icon: <IconFinance />,
    restrict: [M, P, AD],
    parent: "Invoices",
    options: {
      order: {
        admin: 6,
      },
    },
  },
  {
    path: "/profile",
    exact: true,
    component: <Profile />,
    title: "Profile",
    menu: false,
  },
];

export const getPrivateRoutes = (userType) => {
  const uTID = USER_TYPE[userType];

  let routes = [];
  if (uTID === USER_TYPE.MERCHANT) {
    const merchantOrder = _.sortBy(
      AUTHENTICATED_ROUTES,
      "options.order.merchant"
    );
    routes = merchantOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID)
    );
  }
  if (uTID === USER_TYPE.PUBLISHER) {
    const publisherOrder = _.sortBy(
      AUTHENTICATED_ROUTES,
      "options.order.publisher"
    );
    routes = publisherOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID)
    );
  }
  if (uTID === USER_TYPE.ADVERTISER) {
    const publisherOrder = _.sortBy(
      AUTHENTICATED_ROUTES,
      "options.order.publisher"
    );
    routes = publisherOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID)
    );
  }
  if (uTID === USER_TYPE.SUPER_ADMIN || uTID === USER_TYPE.ADMIN) {
    const uTID_ = USER_TYPE.SUPER_ADMIN;
    const adminOrder = _.sortBy(AUTHENTICATED_ROUTES, "options.order.admin");
    routes = adminOrder.filter(
      (item) => !item.restrict?.find((m) => m === uTID_)
    );
  }

  return routes;
};
