import { Column, Line } from "@ant-design/plots";
import { Empty } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getClickImpression } from "@/app/local/reportSlice";
import { EmptyChart } from "@/assets";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { dateFormat, kFormatter } from "@/utils/helper";

import TileNumber from "../micro/TileNumber";
import { COLORS } from "./colors";
import WidgetWrapper from "./micro/WidgetWrapper";
import "./styles.less";

const sampleData = [
  {
    pivot: "2023-01-10",
    total_clicks: 1,
    banner_widget_count: 0,
    product_widget_count: 1,
  },
  {
    pivot: "2023-01-24",
    total_clicks: 6,
    banner_widget_count: 0,
    product_widget_count: 6,
  },
  {
    pivot: "2023-01-25",
    total_clicks: 48,
    banner_widget_count: 0,
    product_widget_count: 48,
  },
  {
    pivot: "2023-01-30",
    total_clicks: 62,
    banner_widget_count: 0,
    product_widget_count: 62,
  },
  {
    pivot: "2023-01-23",
    total_clicks: 1,
    banner_widget_count: 1,
    product_widget_count: 0,
  },
];

const AdminViewClickChart = (props) => {
  const { title } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [totalData, setTotalData] = useState({});

  const [data, setData] = useState(sampleData);
  const [mode, setMode] = useState("LINE");

  const { adminClickImpression, adminClickImpressionLoading: ld } = useSelector(
    (state) => state.report
  );
  const [context, dispatch_] = useContext(DashboardContext);

  useEffect(() => {
    onSubmit();
  }, [context]);

  useEffect(() => {
    if (adminClickImpression) {
      const data_ = [...(adminClickImpression?.data || [])];
      const temps = [];
      const total = { clicks: 0, views: 0 };
      data_.map((item, i) => {
        const dt = dateFormat(item.pivot, "S2");
        temps.push({
          // name: t("dashboard.finalized"),
          month: dt,
          value: item.total_clicks,
          type: "dashboard.click",
        });
        total.clicks += item.total_clicks || 0;

        temps.push({
          // name: t("dashboard.issued"),
          month: dt,
          value: item.total_impressions,
          type: "dashboard.view",
        });
        total.views += item.total_impressions || 0;
      });
      setTotalData(total);
      setData(temps);
    }
  }, [adminClickImpression]);

  const lineConfig = useMemo(() => {
    return {
      data,
      padding: [30, 20, 60, 60],
      xField: "month",
      yField: "value",
      seriesField: "type",
      limitInPlot: false,
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      legend: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v),
        },
      },
      height: 435,
      color: ({ type, year }, ...x) => {
        switch (type) {
          case "dashboard.view":
            return COLORS.DASHBOARD_GREEN;
          case "dashboard.click":
            return COLORS.DASHBOARD_RED;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
    };
  }, [data]);

  const columnConfig = useMemo(() => {
    return {
      data,
      xField: "month",
      yField: "value",
      seriesField: "type",
      legend: false,
      height: 230,
      isGroup: true,
      limitInPlot: false,
      tooltip: {
        formatter: (datum) => {
          return { name: t(datum.type), value: datum.value };
        },
      },
      slider: data.length > 15 ? { start: 0.5, end: 1 } : "",
      padding: [30, 5, 70, 45],
      columnStyle: {
        radius: [10, 10, 0, 0],
      },
      yAxis: {
        label: {
          formatter: (v) => kFormatter(v),
        },
      },
      color: ({ type, year }, ...x) => {
        switch (type) {
          case "dashboard.view":
            return COLORS.DASHBOARD_GREEN;
          case "dashboard.click":
            return COLORS.DASHBOARD_RED;
          default:
            return COLORS.DASHBOARD_BLUE;
        }
      },
      theme: {
        styleSheet: {
          fontFamily: "IRANYekan",
        },
      },
    };
  }, [data]);

  const onSubmit = (e) => {
    dispatch(
      getClickImpression({
        web_store_ids: context.webstore,
        media_ids: e?.medias || context?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  return (
    <WidgetWrapper
      setMode={setMode}
      title={`dashboard.${title}`}
      onSubmit={onSubmit}
      lin
      className="admin_commission_chart"
    >
      <div
        className="horizontal-chart-bar"
        dir="ltr"
        style={{ direction: "ltr" }}
      >
        <div className="tile-bar">
          <TileNumber
            title={t("dashboard.finalClick")}
            number={totalData.clicks}
            align="center"
            color="color_1"
          />
          <TileNumber
            title={t("dashboard.finalView")}
            number={totalData.views}
            align="center"
            color="color_5"
          />
        </div>
        <ChartHolder loading={ld} len={data?.length} height="230px">
          {mode === "BAR" && <Column {...columnConfig} loading={ld} />}
          {mode === "LINE" && <Line {...lineConfig} loading={ld} />}
        </ChartHolder>
      </div>
    </WidgetWrapper>
  );
};

export const ChartHolder = (props) => {
  return (
    <>
      {!props.len && !props.loading ? (
        <div
          style={{
            height: props.height,
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Empty
            className="img-empty"
            image={EmptyChart}
            // style={{ height: props.height }}
          />
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

ChartHolder.propTypes = {
  len: PropTypes.any,
  height: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool,
};

AdminViewClickChart.propTypes = {
  isMerchant: PropTypes.bool,
  title: PropTypes.string,
};

export default AdminViewClickChart;
