import { Card, Col, Row } from "antd";
import { t } from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCostOverview, getMerchantOverview } from "@/app/local/reportSlice";
import MainContainer from "@/components/container/MainContainer";
import AdminCommissionChart from "@/components/dashboard/AdminCommissionChart";
import AdminConversionRateChart from "@/components/dashboard/AdminConversionRateChart";
import AdminFinalOrdersChart from "@/components/dashboard/AdminFinalOrdersChart";
import AdminViewClickChart from "@/components/dashboard/AdminViewClickChart";
import AmountOrdersChart from "@/components/dashboard/AmountOrdersChart";
import FilterHeader from "@/components/dashboard/FilterHeader";
import OrderPricesChart from "@/components/dashboard/OrderPricesChart";
import TileAnalyticCard from "@/components/dashboard/TileAnalyticCard";
import TopCategories from "@/components/dashboard/TopCategories";
import TopCategoriesByCount from "@/components/dashboard/TopCategoriesByCount";
import PageTitle from "@/components/general/PageTitle";
import { formatDate, serverAcceptableDate } from "@/utils/helper";

import { DashboardContext } from "./DashboardAdmin";
import "./styles.less";

const initialState = {
  // webstore: [1],
  period: {
    fromDate: serverAcceptableDate(moment().add(-1, "month"), true),
    toDate: serverAcceptableDate(formatDate(moment()), false, true),
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_date":
      return { ...state, period: action.value ? action.value : {} };
    case "set_webstore":
      return { ...state, webstore: action.value ? action.value : [] };
    case "set_all":
      return { ...state, ...action.value };
    default:
      return { ...state, [action.type]: action.value };
    // throw new Error();
  }
};

const DashboardMerchant = () => {
  const {
    adminCostOverview,
    adminCostOverviewLoading,
    reportMerchantOverviewLoading,
    reportMerchantOverview,
  } = useSelector((state) => state.report);
  const dispatch = useDispatch();

  const [context, dispatch_] = useReducer(reducer, initialState);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      getCostOverview({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );

    dispatch(
      getMerchantOverview({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (adminCostOverview) {
      const data_ = adminCostOverview?.data;
      setData(data_);
    }
  }, [adminCostOverview]);

  const d = reportMerchantOverview?.data;

  return (
    <DashboardContext.Provider value={[context, dispatch_]}>
      <div className="dashboard">
        <PageTitle title={"Dashboard"} print />

        <MainContainer>
          <>
            <FilterHeader hideFilterType />

            <Row gutter={[24, 24]}>
              <Col lg={19} md={24} xs={24}>
                <OrderPricesChart type="AMOUNT_ORDERS" />
              </Col>

              <Col lg={5} md={24} xs={24} className="tile-cpo-merchant">
                <TileAnalyticCard
                  name="CPO"
                  price={data?.avg_cpo_final_order}
                  color="color_1"
                  toolTip={"COST PER ORDER"}
                />
                <TileAnalyticCard
                  name="AOV"
                  price={data?.aov_finalized_order}
                  color="color_2"
                  toolTip={"AVERAGE ORDER VALUE"}
                />
              </Col>

              {/* <Col lg={5} md={24} xs={24} className="commission__info">
                <CardInfo
                  title="activeMediasCount"
                  img="PinkVol"
                  value={d?.total_active_media}
                />
                <CardInfo
                  title="activeLinksCount"
                  img="OrangeAttach"
                  value={d?.total_active_link}
                />
              </Col> */}

              <Col md={24} xs={24}>
                <AmountOrdersChart type="NUMBER_ORDERS" />
              </Col>

              <Col md={12} xs={24}>
                <AdminCommissionChart title="commissionsTitle" />
              </Col>
              <Col md={12} xs={24}>
                <AdminViewClickChart title="viewClickTitle" />
              </Col>

              <Col md={18} xs={24}>
                <AdminConversionRateChart title="conversionRate" />
              </Col>
              <Col md={6} xs={24}>
                <AdminFinalOrdersChart title="finalOrders" />
              </Col>

              <Col md={12} xs={24}>
                <Card
                  title={
                    <span className="title">
                      {t(`dashboard.topCategoriesPrice`)}
                      <i>{t(`dashboard.topCategoriesPriceSub`)}</i>
                    </span>
                  }
                  className="admin_commission_chart top_in_affilio"
                >
                  <TopCategories type="FINALIZED_ORDER" />
                </Card>
              </Col>
              <Col md={12} xs={24}>
                <Card
                  title={
                    <span className="title">
                      {t(`dashboard.topCategoriesPrice`)}
                      <i>{t(`dashboard.topCategoriesPriceCountSub`)}</i>
                    </span>
                  }
                  className="admin_commission_chart top_in_affilio"
                >
                  <TopCategoriesByCount type="COUNT_ORDER" />
                </Card>
              </Col>
            </Row>
          </>
        </MainContainer>
        <br />
      </div>
    </DashboardContext.Provider>
  );
};

export default DashboardMerchant;
