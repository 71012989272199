import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCostOverview } from "@/app/local/reportSlice";
import MainContainer from "@/components/container/MainContainer";
import AdminCommissionChart from "@/components/dashboard/AdminCommissionChart";
import AdminConversionRateChart from "@/components/dashboard/AdminConversionRateChart";
import AdminFinalOrdersChart from "@/components/dashboard/AdminFinalOrdersChart";
import AdminViewClickChart from "@/components/dashboard/AdminViewClickChart";
import AmountOrdersChart from "@/components/dashboard/AmountOrdersChart";
import FilterHeader from "@/components/dashboard/FilterHeader";
import OrderPricesChart from "@/components/dashboard/OrderPricesChart";
import PublishersAndLinksAndActiveMedias from "@/components/dashboard/PublishersAndLinksAndActiveMedias";
import TileAnalyticCard from "@/components/dashboard/TileAnalyticCard";
import TopInAffilio from "@/components/dashboard/TopInAffilio";
import PageTitle from "@/components/general/PageTitle";
import { formatDate, serverAcceptableDate } from "@/utils/helper";

import "./styles.less";

export const DashboardContext = createContext([]);

const reducer = (state, action) => {
  switch (action.type) {
    case "set_date":
      return { ...state, period: action.value ? action.value : {} };
    case "set_webstore":
      return { ...state, webstore: action.value ? action.value : [] };
    case "set_all":
      return { ...state, ...action.value };
    default:
      return { ...state, [action.type]: action.value };
    // throw new Error();
  }
};

const DashboardAdmin = () => {
  const initialState = {
    // webstore: [],
    period: {
      fromDate: serverAcceptableDate(moment().add(-1, "month"), true),
      toDate: serverAcceptableDate(formatDate(moment()), false, true),
    },
  };

  const { adminCostOverview, adminCostOverviewLoading } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();

  const [context, dispatch_] = useReducer(reducer, initialState);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(
      getCostOverview({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (adminCostOverview) {
      const data_ = adminCostOverview?.data;
      setData(data_);
    }
  }, [adminCostOverview]);

  return (
    <DashboardContext.Provider value={[context, dispatch_]}>
      <div className="dashboard">
        <PageTitle title={"Dashboard"} />

        <MainContainer>
          <>
            <FilterHeader hideFilterType />

            <Row gutter={[24, 24]}>
              {/* <Col lg={6} md={12} xs={24} className="tile-analytic">
                <TileAnalyticCard
                  name="CAC"
                  price={data?.cac}
                  color="color_3"
                  toolTip={"CUSTOMER ACQUISITION COST"}
                />
              </Col> */}
              {/* <Col lg={6} md={12} xs={24} className="tile-analytic">
                <TileAnalyticCard
                  name="NCR"
                  value={data?.new_customer_rate * 100}
                  color="color_4"
                  isPercent
                  toolTip={"NEW CUSTOMER RATE"}
                />
              </Col> */}

              <Col lg={18} md={18} xs={24}>
                <OrderPricesChart type="AMOUNT_ORDERS" />
              </Col>
              <Col lg={6} md={6} xs={24} className="tile-analytic">
                <TileAnalyticCard
                  name="CPO"
                  price={data?.avg_cpo_final_order}
                  color="color_1"
                  toolTip={"COST PER ORDER"}
                />
                <TileAnalyticCard
                  name="AOV"
                  price={data?.aov_finalized_order}
                  color="color_2"
                  toolTip={"AVERAGE ORDER VALUE"}
                />
              </Col>

              <Col md={24} xs={24}>
                <AmountOrdersChart type="NUMBER_ORDERS" />
              </Col>

              <Col md={12} xs={24}>
                <AdminCommissionChart title="commissionsTitle" />
              </Col>
              <Col md={12} xs={24}>
                <AdminViewClickChart title="viewClickTitle" />
              </Col>

              <Col md={6} xs={24}>
                <AdminFinalOrdersChart title="finalOrders" />
              </Col>
              <Col md={18} xs={24}>
                <AdminConversionRateChart title="conversionRate" />
              </Col>

              <Col md={14} xs={24}>
                <TopInAffilio title="topTenInAffilio" />
              </Col>
              <Col md={10} xs={24}>
                <PublishersAndLinksAndActiveMedias title="publishersAndLinksAndActiveMedias" />
              </Col>
            </Row>
          </>
        </MainContainer>
        <br />
      </div>
    </DashboardContext.Provider>
  );
};

export default DashboardAdmin;
