import { ConfigProvider, Form, Input, Select } from "antd";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { MEDIA_TYPE_AVAILABLE } from "@/utils/constants/apiConstants";

import "./input.less";

const { Option } = Select;

const InputUrl = (props) => {
  const {
    name,
    placeholder,
    size,
    label,
    disabled,
    mediaType,
    form,
    onPaste,
    requiredText,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    form?.setFieldsValue({ url_link: "" });
  }, [mediaType]);

  const selectBefore = (
    <Form.Item name={name + "_type"} noStyle>
      <Select className="select-before" disabled={disabled}>
        <Option className="select-before__option" value="http://">
          http://
        </Option>
        <Option className="select-before__option" value="https://">
          https://
        </Option>
      </Select>
    </Form.Item>
  );

  const handlePattern = () => {
    if (mediaType === MEDIA_TYPE_AVAILABLE.Telegram) {
      return /^(www\.)?t\.me\/[a-zA-Z0-9_]+$/g;
    } else if (mediaType === MEDIA_TYPE_AVAILABLE.Instagram) {
      return /^(www\.)?instagram.com\/[a-zA-Z0-9._-]+\/?$/i;
    } else if (mediaType === MEDIA_TYPE_AVAILABLE.YouTube) {
      return /^(www\.)?youtube.com\/@[a-zA-Z0-9_-]+$/g;
    } else if (mediaType === MEDIA_TYPE_AVAILABLE.Aparat) {
      return /^(www\.)?aparat.com\/[a-zA-Z0-9._-]+\/?$/g;
    } else {
      return /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?/;
    }
  };

  const deleteExtraOnPaste = (v) => {
    v.preventDefault();
    let paste = v?.clipboardData.getData("text");
    paste = paste.replace(/^https:\/\//, "").replace(/^http:\/\//, "");
    form?.setFieldsValue({
      url_link: paste,
    });
  };

  return (
    <ConfigProvider direction="ltr">
      <Form.Item
        name={name + "_link"}
        rules={[
          {
            required: true,
            message: requiredText ? requiredText : t("required"),
          },
          onPaste
            ? {
                // type: "url",
                message: t("general.urlFormatNotValid"),
                pattern: handlePattern(),
              }
            : {},
        ]}
        label={label}
      >
        <Input
          size={size || "large"}
          placeholder={placeholder}
          // addonBefore={"http(s)://"}
          addonBefore={selectBefore}
          style={{ direction: "ltr" }}
          disabled={disabled}
          onPaste={onPaste ? (v) => deleteExtraOnPaste(v) : undefined}
        />
      </Form.Item>
    </ConfigProvider>
  );
};

InputUrl.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  rules: PropTypes.array,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  offAddon: PropTypes.bool,
  mediaType: PropTypes.number,
  form: "",
  onPaste: "",
  requiredText: PropTypes.string,
};

export default InputUrl;
