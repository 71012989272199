import {
  HomeOutlined,
  SecurityScanOutlined as IconChangePassword,
  PoweroffOutlined as IconExit,
  UserOutlined as IconHi,
} from "@ant-design/icons";
import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Dropdown,
  Menu,
  PageHeader,
  Select,
  Space,
  message,
} from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { accountLogout, configAdd } from "@/app/local/accountSlice";
import { appLoadingBar } from "@/app/local/appSlice";
import { getPrivateRoutes } from "@/app/routes";
import { getPrivateRoutes as getPrivateRoutesSSP } from "@/app/routes-ssp";
import LogoSSP from "@/assets/images/logo-sidebar-ssp-dark.svg";
import Logo from "@/assets/images/logo.svg";
import ProfileImage from "@/assets/images/profile.svg";
import ProfileSSPImage from "@/assets/images/ssp/profile.svg";
import { IranFlag, TourIcon, UsaFlag } from "@/assets/pageIcons";
import { LANGUAGE, USER_CONFIG_TYPE } from "@/utils/constants/apiConstants";
import {
  ACADEMIA_LINK,
  ACADEMIA_LINK_SSP,
  MANUAL_CONFIG,
} from "@/utils/constants/configConstants";
import { getMatchPath, setLocalLang, titleHandler } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import useConfig from "@/utils/hooks/useConfig";
import { clearStorage } from "@/utils/utils";

import ButtonCollapse from "../extra/ButtonCollapse";
import PopupAdvertise from "../extra/PopupAdvertise";
import HelpTourModal from "../onboarding/HelpTourModal";
import "./Appbar.less";

export const AppBar = (props) => {
  const { isWizard } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { roleId, isAdmin, lang } = useAccount();
  const [helpTour, setHelpTour] = useState();
  const { isSSP } = useConfig();

  const { profile, loading } = useSelector((state) => state.account);
  const [defaultLang, setDefaultLang] = useState({
    value: null,
    label: null,
  });

  const userId = profile?.data?.user_id;
  const languageOptions = [
    {
      value: LANGUAGE.PERSIAN,
      title: t("general.persian"),
      icon: IranFlag,
    },
    {
      value: LANGUAGE.ENGLISH,
      title: t("general.english"),
      icon: UsaFlag,
    },
  ];
  const { Option } = Select;
  const logoutApp = async () => {
    clearStorage();
    await dispatch(accountLogout());
    window.location.href = "/login";
  };

  const getUserName = () => {
    if (isAdmin) {
      return `${t("user.types.ADMIN")}`;
    }
    const { first_name, last_name, company_name } =
      profile?.userDetail?.data || {};
    const name = first_name
      ? `${first_name} ${last_name}`
      : `${company_name || ""}`;
    return `${name}`;
  };

  useEffect(() => {
    lang === LANGUAGE.ENGLISH &&
      setDefaultLang({
        value: languageOptions[1].value,
        label: <img src={languageOptions[1].icon} />,
      });
    lang === LANGUAGE.PERSIAN &&
      setDefaultLang({
        value: languageOptions[0].value,
        label: <img src={languageOptions[0].icon} />,
      });
  }, [lang]);

  const setOpenHelpTour = (e) => {
    setHelpTour(true);
  };

  const changeLang = async (lang) => {
    dispatch(appLoadingBar(true));
    const result = await dispatch(
      configAdd({
        id: userId,
        type: USER_CONFIG_TYPE.Culture,
        value: lang.value,
      })
    );
    if (result?.payload?.success) {
      // i18n.changeLanguage(lang.value);
      setDefaultLang(lang);
      setLocalLang(lang.value);
      // !lang.value && (await dispatch(accountUserDetail()));
      //TODO FIX RELOAD
      window.location.reload();
    } else {
      message.error({
        content: result?.payload?.Message || t("general.unHandleErrorMessage"),
        key: "main-error",
        duration: 1,
      });
    }
    dispatch(appLoadingBar(false));
  };

  const menuDropDown = useCallback(() => {
    if (isWizard) {
      const menu = (
        <Menu className="profile-menu">
          <Menu.Item onClick={logoutApp}>
            <Button type="text">{t("general.logout")}</Button>
          </Menu.Item>
        </Menu>
      );
      return menu;
    } else {
      const menu = (
        <Menu className="profile-menu">
          <Menu.Item className="menu-name" icon={<IconHi />}>
            {getUserName()}
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigate(isAdmin ? "setting" : "profile");
              if (!isAdmin) {
                clarity.setTag("user-profile", "");
              }
            }}
            icon={<IconChangePassword />}
          >
            {isAdmin ? t("user.changePassword") : t("page.Profile")}
          </Menu.Item>
          <Menu.Item onClick={logoutApp} icon={<IconExit />}>
            {t("general.logout")}
          </Menu.Item>
        </Menu>
      );
      return menu;
    }
  }, [isWizard]);

  let extra = [];
  if (!isWizard) {
    extra.push(
      <Space key={1} className="appbar__icons no-display" size={16}>
        {!isAdmin && MANUAL_CONFIG.IS_TOUR_ACTIVE ? (
          <Button
            type="default"
            onClick={setOpenHelpTour}
            className="site-header__help"
          >
            <img src={TourIcon} className="site-header__icon" />
            {t("general.helpTour")}
          </Button>
        ) : null}
        <HelpTourModal open={helpTour} setOpen={setHelpTour} />
        <PopupAdvertise />
      </Space>
    );
  }

  extra.push(
    <Dropdown
      overlay={menuDropDown()}
      placement="bottomLeft"
      className="profile-menu"
      key={2}
      trigger={["click"]}
    >
      <div className="btn-profile">
        <span>{getUserName()}</span>
        <Avatar size={22} src={isSSP ? ProfileSSPImage : ProfileImage}></Avatar>
      </div>
    </Dropdown>
  );
  // CHANGE LANGUAGE DISABLE
  // extra.push(
  //   <Space key={3}>
  //     <Select
  //       labelInValue
  //       loading={loading}
  //       style={{
  //         width: 60,
  //       }}
  //       dropdownMatchSelectWidth={false}
  //       onChange={changeLang}
  //       value={defaultLang}
  //       dropdownStyle={{
  //         border: "1px #C2CCD9 solid",
  //         borderRadius: "8px",
  //       }}
  //     >
  //       {languageOptions.map((item) => (
  //         <Option value={item.value} key={item.value}>
  //           <span style={{ marginLeft: "15px" }}>{item.title}</span>
  //           <img src={item.icon} />
  //         </Option>
  //       ))}
  //     </Select>
  //   </Space>
  // );
  extra.push(
    <Button
      type="primary"
      ghost
      href={isSSP ? ACADEMIA_LINK_SSP : ACADEMIA_LINK}
      target="_blank"
      // size="large"
      key={4}
      hidden
      className="no-display"
    >
      {t("general.academia")}
    </Button>
  );

  return (
    <>
      <PageHeader
        className="site-header"
        title={
          isWizard ? (
            <a className="site-header__link" onClick={() => navigate("/")}>
              <Space>
                <img src={isSSP ? LogoSSP : Logo} className="mini-logo" />
              </Space>
            </a>
          ) : (
            <ButtonCollapse
              setCollapsed={props.setCollapsed}
              collapsed={props.collapsed}
            />
          )
        }
        subTitle={isWizard ? <WizardBreadcrumb_ /> : false}
        extra={extra}
      />
    </>
  );
};

export const WizardBreadcrumb_ = () => {
  const navigate = useNavigate();

  return (
    <Col xs={0} md={24} style={{ marginRight: 10 }}>
      {/* <Space> */}
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item href="#">
          <span>ثبت نام</span>
        </Breadcrumb.Item> */}
        <Breadcrumb.Item>{t("general.registerComplete")}</Breadcrumb.Item>
      </Breadcrumb>
      {/* </Space> */}
    </Col>
  );
};

const generatePath = (routes, currentPath, stack) => {
  const x = routes.find((item) => getMatchPath(currentPath, item.path));
  if (x?.parent) {
    stack.push(x);
    return generatePath(routes, x?.parent, stack);
  } else {
    x?.title && stack.push(x);
  }
  return stack.reverse();
};
const MainBreadcrumb = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const currentPage = location.pathname;
  const { role } = useAccount();
  const { isSSP } = useConfig();
  const routes = isSSP ? getPrivateRoutesSSP(role) : getPrivateRoutes(role);

  const stack = [];
  const crumbs = generatePath(routes, currentPage, stack);

  useEffect(() => {
    titleHandler(role);
  }, [currentPage]);

  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link to={"/"}>
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {crumbs.map((item, i) => (
        <Breadcrumb.Item key={item.title}>
          {crumbs.length - 1 !== i ? (
            <Link to={item.path}>{t("page." + item.title)}</Link>
          ) : (
            t("page." + item.title)
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

AppBar.propTypes = {
  isWizard: PropTypes.bool,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};

export default AppBar;
