import { Alert, Card, Col, Row } from "antd";
import { t } from "i18next";
import moment from "moment-jalaali";
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCommisionOverview, getCostOverview } from "@/app/local/reportSlice";
import MainContainer from "@/components/container/MainContainer";
import AdminConversionRateChart from "@/components/dashboard/AdminConversionRateChart";
import AdminFinalOrdersChart from "@/components/dashboard/AdminFinalOrdersChart";
import AdminViewClickChart from "@/components/dashboard/AdminViewClickChart";
import AmountOrdersChart from "@/components/dashboard/AmountOrdersChart";
import FilterHeader from "@/components/dashboard/FilterHeader";
import OrderPricesChart from "@/components/dashboard/OrderPricesChart";
import TileAnalyticCard from "@/components/dashboard/TileAnalyticCard";
import TopCategories from "@/components/dashboard/TopCategories";
import TopLinks from "@/components/dashboard/TopLinks";
import CardInfo from "@/components/dashboard/micro/CardInfo";
import PageTitle from "@/components/general/PageTitle";
import { formatDate, serverAcceptableDate } from "@/utils/helper";

import { DashboardContext } from "./DashboardAdmin";
import "./styles.less";

// export const DashboardContext = createContext([]);
const initialState = {
  // webstore: [1],
  period: {
    fromDate: serverAcceptableDate(moment().add(-1, "month"), true),
    toDate: serverAcceptableDate(formatDate(moment()), false, true),
  },
};
const reducer = (state, action) => {
  switch (action.type) {
    case "set_date":
      return { ...state, period: action.value ? action.value : {} };
    case "set_webstore":
      return { ...state, webstore: action.value ? action.value : [] };
    case "set_all":
      return { ...state, ...action.value };
    default:
      return { ...state, [action.type]: action.value };
    // throw new Error();
  }
};

export default function DashboardPublisher() {
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const dispatch = useDispatch();
  const [context, dispatch_] = useReducer(reducer, initialState);
  const { adminCostOverview, adminCostOverviewLoading } = useSelector(
    (state) => state.report
  );
  const { adminCommisionOverview, adminCommisionOverviewLoading } = useSelector(
    (state) => state.report
  );

  useEffect(() => {
    dispatch(
      getCostOverview({
        web_store_ids: context.webstore,
        media_ids: context?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  }, [context]);

  useEffect(() => {
    if (adminCostOverview) {
      const data_ = adminCostOverview?.data;
      setData(data_);
    }
  }, [adminCostOverview]);

  useEffect(() => {
    if (adminCommisionOverview) {
      const data_ = [...(adminCommisionOverview?.data || [])];
      const temps = [];
      let total = {
        total_commission: 0,
        invoiced_commission: 0,
        finalized_commission: 0,
        paid_commission: 0,
      };
      data_.map((item, i) => {
        temps.push({
          name: t("dashboard.total_commission"),
          value: item.total_commission || 0,
          type: item.type,
        });
        total.total_commission += item.total_commission || 0;

        temps.push({
          name: t("dashboard.invoiced_commission"),
          value: item.invoiced_commission || 0,
          type: item.type,
        });
        total.invoiced_commission += item.invoiced_commission || 0;

        temps.push({
          name: t("dashboard.finalized"),
          value: item.finalized_commission || 0,
          type: item.type,
        });
        total.finalized_commission += item.finalized_commission || 0;

        temps.push({
          name: t("dashboard.paid_commission"),
          value: item.paid_commission || 0,
          type: item.type,
        });
        total.paid_commission += item.paid_commission || 0;
      });
      setTotalData(total);
    }
  }, [adminCommisionOverview]);

  useEffect(() => {
    onSubmit();
  }, [context]);

  const onSubmit = (e) => {
    dispatch(
      getCostOverview({
        web_store_ids: context.webstore,
        media_ids: e?.medias,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );

    dispatch(
      getCommisionOverview({
        web_store_ids: context.webstore,
        media_ids: e?.medias,
        link_type_report_types: e?.link_type,
        from: context.period.fromDate,
        to: context.period.toDate,
        publisher_ids: context?.publisher_ids,
        filter_date_by: context?.filter_date_by,
      })
    );
  };

  return (
    <DashboardContext.Provider value={[context, dispatch_]}>
      <div className="dashboard">
        <PageTitle title={"Dashboard"} />

        <MainContainer>
          <>
            <div className={"media__alert"} style={{ marginBottom: "24px" }}>
              <Alert
                type="warning"
                showIcon
                message={
                  <div className="alert">
                    <span>{t("alert.dashboardAlert")}</span>
                  </div>
                }
              ></Alert>
            </div>
            <FilterHeader hideFilterType />

            <Row gutter={[24, 24]}>
              <Col lg={19} md={24} xs={24}>
                <AdminViewClickChart title="viewClickTitle" />
              </Col>

              <Col lg={5} md={24} xs={24} className="tile-analytic">
                <>
                  {/* <TileAnalyticCard
                    name="AOV"
                    price={data?.aov_finalized_order}
                    color="color_2"
                    toolTip={"AVERAGE ORDER VALUE"}
                  /> */}
                  <CardInfo
                    title="finalized_commission"
                    img="KiseGreen2"
                    value={totalData?.finalized_commission || 0}
                  />
                  <CardInfo
                    title="total_commission"
                    img="KiseGreen0"
                    value={totalData?.total_commission || 0}
                  />
                  <TileAnalyticCard
                    name="CPO"
                    price={data?.avg_cpo_final_order}
                    color="color_1"
                    toolTip={"COST PER ORDER"}
                  />
                </>
              </Col>

              {/* <Col
                lg={24}
                md={24}
                xs={24}
                className="commission__info__publisher"
              >
                <CardInfo
                  title="total_commission"
                  img="KiseGreen0"
                  value={totalData?.total_commission || 0}
                />
                <CardInfo
                  title="invoiced_commission"
                  img="KiseGreen1"
                  value={totalData?.invoiced_commission || 0}
                />
                <CardInfo
                  title="finalized_commission"
                  img="KiseGreen2"
                  value={totalData?.finalized_commission || 0}
                />
                <CardInfo
                  title="paid_commission"
                  img="KiseGreen"
                  value={totalData?.paid_commission || 0}
                />
              </Col> */}
              <Col lg={24} md={24} xs={24}>
                <OrderPricesChart type="AMOUNT_ORDERS" />
              </Col>

              <Col md={24} xs={24}>
                <AmountOrdersChart type="NUMBER_ORDERS" />
              </Col>

              <Col md={6} xs={24}>
                <AdminFinalOrdersChart title="finalOrders" />
              </Col>

              <Col md={18} xs={24}>
                <AdminConversionRateChart title="conversionRate" />
              </Col>

              <Col
                md={12}
                xs={24}
                className="admin_commission_chart top_in_affilio"
              >
                <Card title={t("dashboard.topLinks")}>
                  <TopLinks pieChart={false} />
                </Card>
              </Col>
              <Col
                md={12}
                xs={24}
                className="admin_commission_chart top_in_affilio"
              >
                <Card title={t("dashboard.topCategories")}>
                  <TopCategories pieChart={false} />
                </Card>
              </Col>
            </Row>
          </>
        </MainContainer>
      </div>
    </DashboardContext.Provider>
  );
}
