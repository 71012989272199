import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import { useAccount } from "@/utils/hooks/useAuth";

import CrispChat from "./CrispChat";
import GoftinoChat from "./GoftinoChat";
import ImberChat from "./ImberChat";
import RayChat from "./RayChat";
import RocketChat from "./RocketChat";
import "./styles.less";

const MainTicketChat = (props) => {
  const [isShowRayChat, setIsShowRayChat] = useState(false);
  const [display, setDisplay] = useState(
    process.env.NODE_ENV === "production" ? "RAYCHAT" : "RAYCHAT"
  );
  const { isAdmin } = useAccount();
  const account = useSelector((state) => state.account);
  const user = account.profile?.data;
  let roleId = user?.user_type;

  useEffect(() => {
    if (!isAdmin && account && user && roleId) {
      setIsShowRayChat(true);
    }
  }, [isAdmin]);

  return (
    <>
      {display === "CRISP" ? <CrispChat setDisplay={setDisplay} /> : ""}
      {display === "IMBER" ? <ImberChat /> : ""}
      {display === "ROCKET" ? <RocketChat /> : ""}
      {display === "GOFTINO" ? <GoftinoChat /> : ""}
      {display === "RAYCHAT" && isShowRayChat ? <RayChat /> : ""}
    </>
  );
};

export default MainTicketChat;
