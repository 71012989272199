import { Card } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import React from "react";
import CountUp from "react-countup";

import {
  KiseGreen,
  KiseGreen0,
  KiseGreen1,
  KiseGreen2,
  KiseOrange,
  OrangeAttach,
  PinkVol,
} from "@/assets";

const Images = {
  PinkVol: PinkVol,
  OrangeAttach: OrangeAttach,
  KiseGreen: KiseGreen,
  KiseGreen0: KiseGreen0,
  KiseGreen1: KiseGreen1,
  KiseGreen2: KiseGreen2,
  KiseOrange: KiseOrange,
};
const CardInfo = (props) => {
  const { title, img, value } = props;

  return (
    <div className="card-info-dashboard">
      <Card>
        <img src={Images[img]} />
        <p>{t(`dashboard.${title}`)}</p>
        <CountUp delay={2} end={value || 0} />
        {/* <span>{numberFormat(value)}</span> */}
      </Card>
    </div>
  );
};
CardInfo.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  value: PropTypes.string,
};

export default CardInfo;
