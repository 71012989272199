import { CloseCircleOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Radio, Row, Select, Tag } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment-jalaali";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { appLoadingBar } from "@/app/local/appSlice";
import DoubleDownIcon from "@/assets/fonts/icomoon/icons/DoubleDownIcon";
import DoubleUpIcon from "@/assets/fonts/icomoon/icons/DoubleUpIcon";
import { DashboardContext } from "@/pages/dashboard/DashboardAdmin";
import { LINK_TYPES } from "@/utils/constants/apiConstants";
import { dateFormat, serverAcceptableDate } from "@/utils/helper";
import { useAccount } from "@/utils/hooks/useAuth";
import { useDeviceDetector } from "@/utils/hooks/useDeviceDetector";

import InputRangePicker from "../input/inputRangePicker";
import SelectFilterTypeDate from "../input/selectFilterTypeDate";
import SelectMedia from "../input/selectMedia";
import SelectPublisher from "../input/selectPublisher";
import SelectWebsite from "../input/selectWebsite";
import "./FilterHeader.less";

const Periods = [
  {
    value: "today",
    label: "today",
  },
  {
    value: "lastWeek",
    label: "lastWeek",
  },
  {
    value: "lastMonth",
    label: "lastMonth",
  },
  {
    value: "month_3",
    label: "month_3",
  },
  {
    value: "month_6",
    label: "month_6",
  },
  {
    value: "favorite_date",
    label: "favorite_date",
  },
];

const FilterHeader = (props) => {
  const { isMerchant, hasMedia, hideWebsites, hideFilterType, ContextProps } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const isMobile = useDeviceDetector();
  const [openAdvanced, setOpenAdvanced] = useState(!isMobile ? true : false);
  const { isAdmin, isPublisher } = useAccount();
  const isInsight = useMatch("/report-insight");
  const [openFilter, setOpenTags] = useState(true);

  const { websitesActive, websitesPublisher, websitesMerchant } = useSelector(
    (state) => state.website
  );
  const { mediaActiveList } = useSelector((state) => state.media);
  const { users } = useSelector((state) => state.adminPublisher);
  const [context, dispatch_] = useContext(ContextProps || DashboardContext);

  useEffect(() => {
    form.setFieldsValue({ period: "lastMonth" });
  }, []);

  const onFinish = (e) => {
    dispatch(appLoadingBar(true));
    let from, to;
    if (e.period_fav) {
      const [a, b] = e.period_fav;
      from = moment(serverAcceptableDate(a)).add(1, "minute").format();
      to = serverAcceptableDate(b);
    } else if (e.period) {
      if (e.period === "lastMonth") {
        from = moment().add(-1, "months").format();
        to = moment().format();
      } else if (e.period === "currentMonth") {
        const first_ = moment().startOf("jMonth").format();
        const end_ = moment().endOf("jMonth").format();
        from = first_;
        to = end_;
      } else if (e.period === "month_3") {
        from = moment().add(-3, "months").format();
        to = moment().format();
      } else if (e.period === "month_6") {
        from = moment().add(-6, "months").format();
        to = moment().format();
      } else if (e.period === "year") {
        from = moment().add(-1, "year").format();
        to = moment().format();
      } else if (e.period === "lastWeek") {
        from = moment().add(-1, "week").format();
        to = moment().endOf("day").format();
      } else if (e.period === "today") {
        from = moment().startOf("day").format();
        to = moment().endOf("day").format();
      }
    }

    setOpenTags(true);
    const webstore = form.getFieldValue("website");
    let x = {
      fromDate: serverAcceptableDate(from, true),
      toDate: serverAcceptableDate(to, false, true),
    };
    if (isInsight) {
      x = {
        fromCompareDate: from,
        toCompareDate: to,
        fromDate: serverAcceptableDate(from, true),
        toDate: serverAcceptableDate(to, false, true),
      };
    }

    dispatch_({
      type: "set_all",
      value: {
        period: x,
        webstore: webstore ? webstore : undefined,
        medias: e.media ? [e?.media] : undefined,
        publisher_ids: e?.publisher,
        filter_date_by: e?.filter_date_by,
      },
    });
    setTimeout(() => {
      dispatch(appLoadingBar(false));
    }, 1000);
    // if (webstore) dispatch_({ type: "set_webstore", value: webstore });
  };

  const webs = websitesActive || websitesPublisher || websitesMerchant;

  const getName = (id, type) => {
    if (type === "website") {
      return webs.data?.find((item) => item.web_store_id == id)?.name;
    } else if (type === "media") {
      return mediaActiveList.data?.find((item) => item.media_id == id)?.name;
    } else if (type === "publisher") {
      return users.data?.find((item) => item.publisher_id == id)?.first_name;
    }
  };

  const onValuesChange = (e) => {};

  const onCloseTag = (type, id) => {
    if (type === "period") {
      form.setFieldsValue({
        period_fav: null,
        period: "lastMonth",
      });
    } else if (type === "website") {
      form.setFieldsValue({ website: [] });
    } else if (type === "media") {
      form.setFieldsValue({ media: null });
    } else if (type === "publisher") {
      form.setFieldsValue({ publisher: [] });
    }
    onFinish(form.getFieldsValue());
  };

  return (
    <Card className="filter-header__card">
      <Form
        className="filter-header"
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row gutter={isMobile ? [8, 0] : [12, 12]}>
          <Col lg={4} md={16} xs={16} hidden={hideWebsites}>
            <SelectWebsite
              placeholder={t("media.selectWebsite")}
              name={"website"}
              size="middle"
              mode="multiple"
            />
          </Col>

          {isAdmin ? (
            <Col lg={3} md={16} xs={16}>
              <SelectPublisher mode="multiple" name="publisher" size="middle" />
            </Col>
          ) : (
            false
          )}

          {isPublisher || hasMedia ? (
            <Col lg={3} md={16} xs={16}>
              <SelectMedia
                name={"media"}
                size="middle"
                // label={t("link.selectMedia")}
                placeholder={t("link.selectMedia")}
              />
            </Col>
          ) : (
            false
          )}

          {!hideFilterType ? (
            <Col lg={3} md={16} xs={16}>
              <SelectFilterTypeDate
                name="filter_date_by"
                placeholder={t("order.filter_by_date_name")}
              />
            </Col>
          ) : (
            false
          )}
          {isMobile && (
            <>
              <Col xs={4} onClick={() => setOpenAdvanced((old) => !old)}>
                <div className="search-more">
                  {!openAdvanced ? <DoubleDownIcon /> : <DoubleUpIcon />}
                </div>
              </Col>
              <Col xs={4} onClick={onFinish}>
                <div className="icon">{<FilterOutlined />}</div>
              </Col>
            </>
          )}

          {isMerchant ? (
            <Col lg={4} md={24} xs={24}>
              <Form.Item name={"link_type"}>
                <Select allowClear placeholder={t("dashboard.selectLinkType")}>
                  {Object.keys(LINK_TYPES).map((key, i) => (
                    <Select.Option key={i} value={LINK_TYPES[key]}>
                      {t(`link.linkType.${key}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
          {openAdvanced && (
            <Col
              lg={isInsight ? 12 : 14}
              md={24}
              xs={24}
              className="filter__period"
            >
              <Form.Item shouldUpdate>
                {(form_) => {
                  const selectedPeriod = form_.getFieldValue("period");
                  if (selectedPeriod !== Periods[5].value)
                    return (
                      <Form.Item name="period">
                        <Radio.Group defaultValue="month_3">
                          {Periods.map((item) => {
                            return (
                              <Radio.Button key={item.value} value={item.value}>
                                {t(`dashboard.filter.${item.label}`)}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    );
                  else
                    return (
                      <div className="filter__date-range">
                        <Form.Item noStyle>
                          <Button
                            onClick={() =>
                              form.setFieldsValue({ period: "lastMonth" })
                            }
                            type="link"
                            icon={
                              <CloseCircleOutlined style={{ color: "red" }} />
                            }
                          ></Button>
                        </Form.Item>
                        <Form.Item name="period_fav" noStyle>
                          <InputRangePicker />
                        </Form.Item>
                        <Form.Item noStyle>
                          <Button type="default">
                            {t("dashboard.filter.favorite_date")}
                          </Button>
                        </Form.Item>
                      </div>
                    );
                }}
              </Form.Item>

              {!isMobile && (
                <Button type="primary" className="filter" htmlType="submit">
                  {t("dashboard.doFilter")}
                </Button>
              )}
            </Col>
          )}
        </Row>

        {openFilter ? (
          <Row style={{ marginTop: "10px" }}>
            {context?.medias?.map((mId) => (
              <Tag
                closable
                key={mId}
                className="filter_tag"
                onClose={() => onCloseTag("media", mId)}
              >
                {getName(mId, "media")}
              </Tag>
            ))}
            {context?.webstore?.map((wId) => (
              <Tag
                closable
                key={wId}
                className="filter_tag"
                onClose={() => onCloseTag("website", wId)}
              >
                {getName(wId, "website")}
              </Tag>
            ))}
            {context?.publisher_ids?.map((pId) => (
              <Tag
                closable
                key={pId}
                className="filter_tag"
                onClose={() => onCloseTag("publisher", pId)}
              >
                {getName(pId, "publisher")}
              </Tag>
            ))}
            <Tag className="filter_tag" onClose={() => onCloseTag("period")}>
              از
              {dateFormat(context.period?.fromDate, "S")} تا{" "}
              {dateFormat(context.period?.toDate, "S")}
            </Tag>
          </Row>
        ) : null}
      </Form>
    </Card>
  );
};

FilterHeader.propTypes = {
  isMerchant: PropTypes.bool,
  hasMedia: PropTypes.bool,
  hideWebsites: PropTypes.bool,
  hideFilterType: PropTypes.bool,
  ContextProps: PropTypes.any,
};

export default FilterHeader;
