import {
  FileTextOutlined as IconInvoice,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Col, Tooltip, message } from "antd";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import React, { useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import {
  getProjectionsByPublisherId,
  syncProjectionsByPublisherId,
} from "@/app/local/adminPublisherSlice";
import TableComponent from "@/components/general/TableComponent";
import SelectPublisher from "@/components/input/selectPublisher";
import FormSearchBar from "@/components/micro/FormSearchBar";
import { ProjectionStatus } from "@/utils/constants/apiConstants";
import { dateFormat, objectR } from "@/utils/helper";

const ProjectionsPublisher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectionsList, publisherProjectionSyncLoading } = useSelector(
    (state) => state.adminPublisher
  );
  const navigate = useNavigate();
  const [form] = useForm();
  const [pageSizeState, setPageSizeState] = useState(10);

  const heads = [
    {
      title: t("projection.id"),
      key: "publisher_projection_id",
    },
    // {
    //   title: t("projection.publisher"),
    //   key: "publisher_name",
    // },
    {
      title: t("projection.bucket"),
      key: "bucket_type",
    },
    {
      title: t("projection.contract"),
      key: "contract_name",
    },
    {
      title: t("projection.title"),
      key: "title",
    },
    {
      title: t("projection.start_date"),
      key: "start_date",
      type: "custom",
    },
    {
      title: t("projection.end_date"),
      key: "end_date",
      type: "custom",
    },
    {
      title: t("projection.payment_date"),
      key: "payment_date",
    },
    {
      title: t("projection.invoice_date"),
      key: "invoice_date",
    },
    {
      title: t("projection.status"),
      key: "status",
    },
    // {
    //   title: t("projection.active_status"),
    //   key: "activeStatus",
    // },
    {
      title: t("projection.invoice"),
      key: "invoice",
      // width: "90px",
      type: "custom",
    },
    // {
    //   title: t("projection.actions"),
    //   key: "actions",
    //   type: "actions",
    // },
  ];

  const itemsList = useMemo(() => {
    let items_ = _.get(projectionsList, "data", []);
    items_ = items_.map((item) => {
      // const actions = [
      //   {
      //     name: t("invoice.detail"),
      //     type: "DETAIL",
      //     handleClick: () =>
      //       item.publisher_invoice_id
      //         ? navigate(`/invoices/${item.publisher_invoice_id}/p`)
      //         : "",
      //   },
      // ];

      const invoice_ = item?.invoice_id ? (
        <Link to={`/invoices/${item.invoice_id}/p`} target="_blank">
          <Button
            type="text"
            icon={<IconInvoice style={{ fontSize: "20px" }} />}
          />
        </Link>
      ) : (
        <div>-</div>
      );
      return {
        ...item,
        title: `${dateFormat(item.start_date)}->${dateFormat(item.end_date)}`,
        merchant_name: item.merchant_id,
        contract_name: item.contract_id,
        bucket_type: item?.bucket_config?.bucket_type,
        start_date: dateFormat(item.start_date),
        end_date: dateFormat(item.end_date),
        invoice_date: dateFormat(item.invoice_date),
        payment_date: dateFormat(item.payment_date),
        last_modify_date: dateFormat(item.last_modify_date, "ST"),
        status: item?.status
          ? t(`projection.status_.${objectR(ProjectionStatus)[item?.status]}`)
          : "",
        invoice: invoice_,
        // actions: actions,
      };
    });
    return items_;
  }, [projectionsList]);

  const items = _.get(projectionsList, "data.total_items", 10);
  const pages = _.get(projectionsList, "data.total_pages", 1);
  const pageSize = pageSizeState || 10;
  const tableInfo = { items, pages, pageSize, serverPagination: false };

  const onFinishSearch = async (e) => {
    const resp =
      e.publisher && (await dispatch(getProjectionsByPublisherId(e.publisher)));
    if (!resp?.payload?.success) {
      message.error({
        key: "ERROR-SEARCH-BAR",
        content: resp?.payload?.Message || t("general.unHandleErrorMessage"),
      });
    }
  };

  const handlePagination = (e) => {
    setPageSizeState(e.pageSize);
  };

  const onClearFilters = (e) => {
    dispatch(getProjectionsByPublisherId({}));
    form.resetFields();
  };

  const onClickSync = async (e) => {
    const pId = form.getFieldValue("publisher");
    if (pId) {
      const result = await dispatch(syncProjectionsByPublisherId(pId));
      if (result?.payload?.success) {
        message.success({
          content: t("general.successFullyDone"),
        });
        onFinishSearch({ publisher: pId });
      } else {
        message.error({
          content:
            result?.payload?.Message || t("general.unHandleErrorMessage"),
        });
      }
    }
  };

  return (
    <div>
      <FormSearchBar
        form_={form}
        name="searchForm"
        onFinish={onFinishSearch}
        onClearFilters={onClearFilters}
      >
        <Col md={6} xs={24}>
          <SelectPublisher name="publisher" />
        </Col>

        <Tooltip title={t("projection.syncMessage")}>
          <Button
            className="sync-btn"
            icon={<SyncOutlined />}
            onClick={onClickSync}
            loading={publisherProjectionSyncLoading}
          >
            {t("projection.sync")}
          </Button>
        </Tooltip>
      </FormSearchBar>

      <TableComponent
        // loading={mediasLoading}
        heads={heads}
        data={itemsList}
        info={tableInfo}
        handlePagination={handlePagination}
      />
    </div>
  );
};

export default ProjectionsPublisher;
