export const ENDPOINTS = {
  // Account
  ACCOUNT_LOGIN: "/Account/Login",
  ACCOUNT_LOGOUT: "/Account/Logout",
  ACCOUNT_REFRESH: "/Account/Refresh",
  ACCOUNT_REGISTER_AND_VERIFY: "/Account/RegisterAndVerify",
  ACCOUNT_REGISTER: "/Account/Register",
  ACCOUNT_SEND_CODE_UNREGISTERED: "/Account/unregistered/SendCode",
  ACCOUNT_SEND_CODE: "/Account/SendCode",
  ACCOUNT_VERIFY: "/Account/Verify",
  ACCOUNT_OLD_VERIFY_EMAIL: "/Account/old/email/verify",
  ACCOUNT_VERIFY_MOBILE: "/Account/mobile/verify",
  ACCOUNT_GET_USER_DETAIL: "/Account/detail",
  ACCOUNT_CHANGE_PASSWORD: "/Account/ChangePassword",
  ACCOUNT_RESET_PASSWORD: "/Account/ResetPassword",
  ACCOUNT_FORGOT_PASSWORD: "/Account/ForgetPassword",
  ACCOUNT_SET_USER_TYPE:
    "/Account/SetUserType?user_id={user_id}&type={roleType}",
  ACCOUNT_REFRESH_CACHE: "/Account/RefreshCache",
  ACCOUNT_FINALIZE_REGISTERING: "/Account/FinalizeRegistering",
  ACCOUNT_GET_ROLES: "/Account/GetRoles",
  ACCOUNT_GET_PRIVILEGES: "/Account/GetPrivileges",
  ACCOUNT_PROFILE_PHOTO_SET: "/Account/profile/photo/set?fileId={fileId}",
  ACCOUNT_GET_USER_ROLE: "/Account/GetUserRole",
  ACCOUNT_OLD_USERNAME: "/Account/old/username/{username}/get",
  ACCOUNT_SEND_EMAIL_CODE: "/Account/send/email/code",
  ACCOUNT_SEND_SMS_CODE: "/Account/send/sms/code",
  ACCOUNT_MOBILE_CHANGE: "/Account/mobile/change",
  ACCOUNT_CONFIG_ADD: "/Account/config/add",

  // ADMIN-CONTRACT
  ADMIN_CONTRACT_UPDATE_STATUS:
    "/AdminContract/{contract_id}/update/status/{status}",
  ADMIN_CONTRACT_TEMPLATE_GET: "/AdminContract/template/{contract_id}/get",
  ADMIN_CONTRACT_TEMPLATE_ADD: "/AdminContract/template/add",
  ADMIN_CONTRACT_TEMPLATE_UPDATE: "/AdminContract/template/update",
  ADMIN_CONTRACT_TEMPLATE_LIST: "/AdminContract/template/list",
  ADMIN_CONTRACT_LIST: "/AdminContract/list",
  ADMIN_CONTRACT_ADD: "/AdminContract/add",
  ADMIN_CONTRACT_NEW_CONTRACT: "/AdminContract/new/user/{userId}/type/{type}",

  //CONTRACT
  CONTRACT_GET_USER_DEFAULT:
    "/Contract/current/detail/user/{user_id}?userType={userType}",
  CONTRACT_APPROVE: "/Contract/{contract_id}/approve",
  CONTRACT_REJECT: "/Contract/{contract_id}/reject",
  CONTRACT_LIST: "/Contract/list",
  CONTRACT_GET: "/Contract/{contract_id}/detail",
  CONTRACT_UPLOAD_PAPER: "/Contract/{contract_id}/uploadPaper/{file_id}",
  CONTRACT_ADD: "/Contract/add",
  CONTRACT_NEW_USER: "/Contract/new/user/{user_id}/type/{type}",
  CONTRACT_UPDATE: "/Contract/update",
  CONTRACT_TEMPLATE_REFRESH_CACHE: "/Contract/template/refreshCache",
  CONTRACT_GET_USER_APPROVE: "/Contract/{contract_id}/user/{user_id}/approve",
  CONTRACT_REFRESH_CACHE: "/Contract/RefreshCache",
  CONTRACT_TEMPLATE_TYPE_GET: "/Contract/template/type/{type}/get",

  // CONTROL PANEL
  CONTROL_PANEL_GET_BODY_CALLBACK: "/ControlPanel/SendPublisherCallBack",
  CONTROL_PANEL_GET_BODY_CALLBACK_META_DATA:
    "/ControlPanel/SendPublisherCallBackMetaData",

  // File
  FILE_ADD: "/File/Add",
  FILE_DETAIL: "/File/{file_id}/detail",
  FILE_DETAIL_QUERY_STRING: "/File/detail?{queryParams}",
  FILE_REFRESH_CACHE: "/File/RefreshCache",
  FILE_STORAGE_REFRESH_CACHE: "/File/storage/RefreshCache",

  // Location
  LOCATION_GET_COUNTRIES: "/Location/GetCountries",
  LOCATION_GET_PROVINCES: "/Location/GetProvinces",
  LOCATION_GET_BY_PARENT_ID: "/Location/GetByParentId/{parent_id}",
  LOCATION_GET_LOCATION_DETAIL: "/Location/GetLocationDetail/{location_id}",
  LOCATION_REFRESH_CACHE: "/Location/RefreshCache",

  // Merchant
  MERCHANT_GET_DETAIL: "/MerchantAccount/GetDetail",
  MERCHANT_SET_COMPANY_INFO: "/MerchantAccount/SetCompanyInfo",
  MERCHANT_SET_CONTACT_INFO: "/MerchantAccount/SetContactInfo",
  MERCHANT_SET_VAT_INFO: "/MerchantAccount/SetVatInfo",

  // Publisher
  PUBLISHER_GET_DETAIL: "/PublisherAccount/GetDetail",
  PUBLISHER_SET_LEGAL_STATUS: "/PublisherAccount/SetLegalStatus/{status}",
  PUBLISHER_SET_PERSONAL_INFO: "/PublisherAccount/SetPersonalInfo",
  PUBLISHER_SET_COMPANY_INFO: "/PublisherAccount/SetCompanyInfo",
  PUBLISHER_SET_CONTACT_INFO: "/PublisherAccount/SetContactInfo",
  PUBLISHER_SET_BANK_ACCOUNT_INFO: "/PublisherAccount/SetBankAccountInfo",
  PUBLISHER_SET_VAT_INFO: "/PublisherAccount/SetVatInfo",
  PUBLISHER_REFRESH_CACHE: "/PublisherAccount/RefreshCache",
  PUBLISHER_CALL_BACK_INFO: "/PublisherAccount/SetCallbackInfo",
  PUBLISHER_HARD_DELETE: "/PublisherAdmin/HardDelete",

  // PublisherAdmin
  PUBLISHER_ADMIN_GET_LIST: "/PublisherAdmin/List",
  PUBLISHER_ADMIN_GET_LIST_ACTIVE: "/PublisherAdmin/active/list?{queryParams}",
  PUBLISHER_ADMIN_GET_DETAIL: "/PublisherAdmin/{userId}/GetDetail",
  PUBLISHER_ADMIN_PROJECTION_LIST:
    "/PublisherAdmin/{publisherId}/projection/list",
  PUBLISHER_ADMIN_PROJECTION_CURRENT:
    "/PublisherAdmin/{publisherId}/projection/current",
  PUBLISHER_ADMIN_PROJECTION_GENERATE:
    "/PublisherAdmin/{publisherId}/projection/generate",
  PUBLISHER_ADMIN_SET_LEGAL_STATUS:
    "/PublisherAdmin/{userId}/SetLegalStatus/{status}",

  // MerchantAdmin
  MERCHANT_ADMIN_GET_LIST: "/MerchantAdmin/List",
  MERCHANT_ADMIN_GET_DETAIL: "/MerchantAdmin/{userId}/GetDetail",
  MERCHANT_ADMIN_PROJECTION_CURRENT:
    "/MerchantAdmin/{merchantId}/projection/current",
  MERCHANT_ADMIN_PROJECTION_ACTIVE_DETAIL:
    "/MerchantAdmin/{merchantId}/projection/active/detail",
  MERCHANT_ADMIN_PROJECTION_LIST: "/MerchantAdmin/{merchantId}/projection/list",
  MERCHANT_ADMIN_PROJECTION_GENERATE:
    "/MerchantAdmin/{merchantId}/projection/generate",
  MERCHANT_ADMIN_CONTRACT_PROJECTION_LIST:
    "/MerchantAdmin/contract/{contractId}/projection/list",
  MERCHANT_ADMIN_PAYOUT_CONFIG_HISTORY:
    "/MerchantAdmin/PayoutConfig/history?{merchantId}{fromDate}{toDate}",
  MERCHANT_ADMIN_PAYOUT_SET_PAYOUT_CONFIG: "/MerchantAdmin/PayoutConfig/set",
  MERCHANT_ADMIN_GROUP_BY_WEB_STORE_ID:
    "/MerchantAdmin/grouping/{webstoreId}/list",
  MERCHANT_ADMIN_ACTIVE_LIST: "/MerchantAdmin/active/list?{queryParams}",
  MERCHANT_ADMIN_GET_GROUPS: "/MerchantAdmin/grouping/{webstoreId}/list",
  MERCHANT_ADMIN_GROUP_ADD: "/MerchantAdmin/grouping/add",
  MERCHANT_ADMIN_GROUP_EDIT: "/MerchantAdmin/grouping/update",
  MERCHANT_ADMIN_GROUP_DELETE: "/MerchantAdmin/grouping/{id}/delete",
  MERCHANT_HARD_DELETE: "/MerchantAdmin/HardDelete",

  // UserAdmin
  USER_ADMIN_SET_STATUS: "/UserAdmin/{userId}/kyc/status/set",
  USER_ADMIN_SET_USER_STATUS: "/UserAdmin/{userId}/status/set",
  USER_ADMIN_ACCOUNT_AUTO_LOGIN: "/UserAdmin/AutoLogin",
  USER_ADMIN_CONFIG_SET: "/UserAdmin/config/add",
  USER_ADMIN_ADD_ROLE: "/UserAdmin/role/add",
  USER_ADMIN_EDIT_ROLE: "/UserAdmin/role/edit",
  USER_ADMIN_DELETE_ROLE: "/UserAdmin/role/{roleId}/delete",
  USER_ADMIN_ROLES: "/UserAdmin/role/list",
  USER_ADMIN_GET_USER_TOLE: "/UserAdmin/{userId}/GetUserRole",
  USER_ADMIN_ASSIGN_USER_ROLE: "/UserAdmin/user/{userId}/role/{roleId}/assign",
  USER_ADMIN_UN_ASSIGN_USER_ROLE:
    "/UserAdmin/user/{userId}/role/{roleId}/delete",
  USER_ADMIN_UPDATE_PRIVILEGES: "/UserAdmin/user/{userId}/updatePrivilege",
  USER_ADMIN_USER_GET_PRIVILEGES: "/UserAdmin/user/{userId}/getPrivilege",
  USER_ADMIN_LIST: "/UserAdmin/admin/list",
  USER_ADMIN_ADD: "/UserAdmin/admin/add",
  USER_ADMIN_EDIT: "/UserAdmin/admin/{userId}/edit",
  USER_ADMIN_SET_ADMIN_STATUS: "/UserAdmin/{userId}/active/status/{isActive}",
  USER_ADMIN_GET_USER_INFO: "/UserAdmin/{userId}/detail",
  USER_ADMIN_CHANGE_PASSWORD: "/UserAdmin/ResetPassword",
  // Shared
  SHARED_CONTENT_TYPE: "/Shared/content/type/{type}",
  SHARED_CONTENT_FILTER_TYPES: "/Shared/content/filterTypes",
  SHARED_SUBJECT_GET: "/Shared/subject/{subject_id}/get",
  SHARED_SUBJECT_LIST: "/Shared/subject/list",
  SHARED_SUBJECT_ADD: "/Shared/subject/add",
  SHARED_SUBJECT_EDIT: "/Shared/subject/edit",
  SHARED_SUBJECT_DELETE: "/Shared/subject/{subject_id}/delete",
  SHARED_SUBJECT_REFRESHED_SUBJECT_CACHE: "/Shared/subject/RefreshSubjectCache",
  SHARED_REASON_LIST: "/Shared/reason/list",
  SHARED_REASON_ADD: "/Shared/reason/add",
  SHARED_REASON_EDIT: "/Shared/reason/edit",
  SHARED_REASON_DELETE: "/Shared/reason/{reasonId}/delete",
  SHARED_PANEL_NOTIFICATION: "/Shared/panelnotification/get",

  // STANDARD SIZE
  SIZE_ADD: "/StandardSize/Add",
  SIZE_GET_LIST: "/StandardSize/GetList",

  // Website
  WEBSITE_LIST: "/WebStore/List",
  WEBSITE_LIST_ACTIVE: "/WebStore/active/list",
  WEBSITE_ADD: "/WebStore/Add",
  WEBSITE_DETAIL: "/WebStore/{webStoreId}/detail",
  WEBSITE_EDIT: "/WebStore/Edit",
  WEBSITE_STATUS: "/WebStore/{webStoreId}/status/{status}",
  WEBSITE_DELETE: "/WebStore/{webStoreId}/delete",
  WEBSITE_MERCHANT_LIST: "/WebStore/merchant/{merchant_id}/list",
  WEBSITE_LEVEL_LIST: "/WebStore/level/list",
  WEBSITE_LEVEL_ADD: "/WebStore/{webStoreId}/level/add",
  WEBSITE_LEVEL_ADD_BATCH: "/WebStore/{webStoreId}/level/add/batch",
  WEBSITE_LEVEL_EDIT: "/WebStore/level/edit",
  WEBSITE_LEVEL_DEFAULT: "/WebStore/level/{level_id}/default/{is_default}",
  WEBSITE_LEVEL_ACTIVE: "/WebStore/level/{level_id}/active/{status}",
  WEBSITE_LEVEL_DETAIL: "/WebStore/level/{level_id}/detail",
  WEBSITE_REFRESH_CACHE: "/WebStore/RefreshCache",
  WEBSITE_COMMISSION_CURRENT:
    "/WebStore/level/{level_id}/commissions/get/current",
  WEBSITE_COMMISSION_ADD: "/WebStore/level/{level_id}/commissions/add",
  WEBSITE_COMMISSION_ADD_EXCEL:
    "/WebStore/level/{level_id}/commissions/addExcel?fileId={fileId}",
  WEBSITE_COMMISSION_DOWNLOAD_EXCEL:
    "/WebStore/level/{level_id}/commissions/get/currentExcel",
  WEBSITE_PUBLISHER_LIST: "/WebStore/publisher/{publisherId}/list",
  WEBSITE_PRODUCT_LIST: "/WebStore/product/list",
  WEBSITE_CATEGORY_LIST: "/WebStore/{webStoreId}/category/list",
  WEBSITE_LEVEL_COMMISSION_APPROVED:
    "/WebStore/level/commissions/{commissionGroupId}/approved",
  WEBSITE_LEVEL_COMMISSION_REJECT:
    "/WebStore/level/commissions/{commissionGroupId}/reject",
  WEBSITE_LEVEL_COMMISSION_CLOSE:
    "/WebStore/level/commissions/{commissionGroupId}/close",
  WEBSITE_LEVEL_COMMISSION_PENDING: "/WebStore/level/commissions/pending/list",
  WEBSITE_LEVEL_COMMISSION_HISTORY:
    "/WebStore/level/{levelId}/commissions/history/list",
  WEBSITE_LEVEL_COMMISSION_GROUP_ID:
    "/WebStore/level/commissionsGroup/{commissionGroupId}/detail",
  WEBSITE_PAYMENT_POLICY_HISTORY:
    "/WebStore/paymentPolicy/history?{queryParams}",
  WEBSITE_PAYMENT_POLICY_CURRENT:
    "/WebStore/{webStoreId}/CalculationMethod/get/current",
  WEBSITE_PAYMENT_POLICY_CALCULATION_METHOD_CURRENT:
    "/WebStore/paymentPolicy/current?webStoreId={webStoreId}",
  WEBSITE_PAYMENT_POLICY_SET: "/WebStore/paymentPolicy/set",
  WEBSITE_LEVEL_ACTIVE_LIST: "/WebStore/level/active/list",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ADD:
    "/WebStore/level/{levelId}/productcommissions/add",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_CURRENT:
    "/WebStore/level/{levelId}/productcommission/get/current",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_APPROVED:
    "/WebStore/level/productcommission/{commissionGroupId}/approved",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ACTIVE:
    "/WebStore/level/productcommission/{commissionGroupId}/activate",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_REJECT:
    "/WebStore/level/productcommission/{commissionGroupId}/activate",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_GET_CURRENT_EXCEL:
    "/WebStore/level/{levelId}/productcommission/get/currentExcel",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_GET_HISTORY_LIST:
    "/WebStore/level/{levelId}/productcommission/history/list",
  WEBSITE_LEVEL_PRODUCT_COMMISSIONS_ADD_EXCEL:
    "/WebStore/level/{levelId}/productcommission/addExcel?fileId={fileId}",

  // Media
  MEDIA_LIST: "/media/list",
  MEDIA_ADD: "/media/add",
  MEDIA_UPDATE_CONTENT_STATUS: "/media/content/status/set",
  MEDIA_UPDATE_OWNERSHIP_STATUS: "/media/ownership/status/set",
  MEDIA_UPDATE_TEST_MODE_STATUS: "/media/test-mode/status/set",
  MEDIA_GET_DETAIL: "/media/{id}/detail",
  MEDIA_UPDATE: "/media/edit",
  MEDIA_ADD_WEBSITE_TO_MEDIA: "/media/{mediaId}/webStore/add",
  MEDIA_REMOVE_WEBSITE_FROM_MEDIA: "/media/{mediaId}/webStore/delete",
  MEDIA_DELETE: "/media/{id}/delete",
  MEDIA_PUBLISHER_WEBSITES:
    "/media/publisher/{publisherId}/webStore/{webStoreId}/list",
  MEDIA_PUBLISHER_ACTIVE_LIST: "/media/publisher/{publisherId}/active/list",
  MEDIA_ADMIN_ACTIVE_LIST: "/media/active/list",
  MEDIA_ADMIN_ACTIVE_LIST_MINI: "/media/active/listmini",
  MEDIA_OWNERSHIP_EMAIL_VERIFY: "/media/ownership/email/verify",
  MEDIA_OWNERSHIP_EMAIL_SEND_CODE:
    "/media/{mediaId}/ownership/email/{email}/sendCode",

  // Links
  LINK_LIST: "/link/list",
  LINK_LIST_GROUP: "/link/group/list",
  LINK_ADD: "/link/add",
  LINK_EDIT: "/link/edit",
  LINK_DETAIL: "/link/{linkId}/detail",
  LINK_DELETE: "/link/{linkId}/delete",
  LINK_STATE: "Link/{linkId}/active/status/set/{status}",
  LINK_LIST_EXCEL: "Link/list/excel",
  LINK_LIST_EXCEL_ASYNC: "/Link/list/excelreq",

  // BANNER
  BANNER_LIST: "/banner/list",
  BANNER_ADD: "/banner/add",
  BANNER_EDIT: "/banner/edit",
  BANNER_SIZE_LIST: "/banner/size/list",
  BANNER_SET_STATUS_DEFAULT:
    "/banner/{bannerId}/set/status/default/{isDefault}",
  BANNER_SET_STATUS: "/banner/{bannerId}/set/status/active/{isEnabled}",
  BANNER_DETAIL: "/banner/{bannerId}/detail",

  // BANNER WIDGET
  BANNER_WIDGETS_LIST: "/bannerWidget/list",
  BANNER_WIDGET_ADD: "/bannerWidget/add",
  BANNER_WIDGET_EDIT: "/bannerWidget/edit",
  BANNER_WIDGET_DELETE: "/bannerWidget/{widgetId}/delete",
  BANNER_WIDGET_ACTIVE_STATUS:
    "/bannerWidget/{widgetId}/active/status/set/{enabled}",
  BANNER_WIDGET_GET_CODE: "/bannerWidget/get/code",

  // ProductWidget
  PRODUCT_WIDGET_ADD: "/productWidget/add",
  PRODUCT_WIDGET_EDIT: "/productWidget/edit",
  PRODUCT_WIDGET_LIST: "/productWidget/list",
  PRODUCT_WIDGET_LIST_SMART: "/productWidget/smart/getlist",
  PRODUCT_WIDGET_GET_CODE: "/productWidget/get/code",
  PRODUCT_WIDGET_SMART_ADD: "/productWidget/smart/add",
  PRODUCT_WIDGET_SMART_EDIT: "/productWidget/smart/edit",
  PRODUCT_WIDGET_SMART_GET_CODE: "/productWidget/smart/get/code",
  PRODUCT_WIDGET_SMART_GET_LIST: "/productWidget/smart/getList",
  PRODUCT_WIDGET_SMART_GET_DETAIL: "/productWidget/smart/{widgetId}/detail",
  PRODUCT_WIDGET_GET_DETAIL: "/productWidget/{widgetId}/detail",
  PRODUCT_WIDGET_GET_DETAIL_ENCRYPTED: "/productWidget/enc/{widgetId}/detail",

  // ORDER
  ORDER_LIST: "/Order/list",
  ORDER_AGGREGATE_LIST: "/Order/aggregate/list",
  ORDER_LIST_EXCEL: "/Order/list/excel",
  ORDER_LIST_EXCEL_ASYNC: "/Order/list/excelreq",
  ORDER_LIST_DRAFT: "/Order/list/draft",

  // INVOICE MERCHANT
  INVOICES_MERCHANT_LIST: "/MerchantInvoice/list",
  INVOICES_MERCHANT_DETAIL: "/MerchantInvoice/{invoiceId}/detail",
  INVOICES_MERCHANT_SET_STATUS: "/MerchantInvoice/status/set",
  INVOICES_MERCHANT_PAYMENT_SET_STATUS: "/MerchantInvoice/payment/status/set",
  INVOICES_MERCHANT_GENERATE_INVOICE: "/MerchantInvoice/Generate",
  INVOICES_MERCHANT_GENERATE_RE_INVOICE:
    "/MerchantInvoice/ReGenerate?{queryParams}",

  // INVOICE PUBLISHER
  INVOICES_PUBLISHER_LIST: "/PublisherInvoice/list",
  INVOICES_PUBLISHER_LIST_EXPORT: "/PublisherInvoice/listexcel",
  INVOICES_PUBLISHER_PAYOUT_LIST: "/PublisherInvoice/payout/list",
  INVOICES_PUBLISHER_DETAIL: "/PublisherInvoice/{invoiceId}/detail",
  INVOICES_PUBLISHER_PAYOUT_SET_STATUS: "/PublisherInvoice/payment/status/set",
  INVOICES_PUBLISHER_SET_STATUS: "/PublisherInvoice/status/set",
  INVOICES_PUBLISHER_SET_LEGAL_BILL:
    "/PublisherInvoice/{invoiceId}/SetLegalPublisherBillFile",
  INVOICES_PUBLISHER_REMOVE_LEGAL_BILL:
    "/PublisherInvoice/{invoiceId}/RemoveLegalPublisherBillFile",
  INVOICES_PUBLISHER_STATUS_BATCHSET: "/PublisherInvoice/status/batchset",
  INVOICES_PUBLISHER_PAYMENT_BATCHSET:
    "/PublisherInvoice/payment/status/batchset",
  INVOICES_PUBLISHER_ADD_INVOICE_DOCUMENTS:
    "/PublisherInvoice/{invoiceId}/AddDocument",
  INVOICES_PUBLISHER_REMOVE_INVOICE_DOCUMENTS:
    "/PublisherInvoice/{invoiceId}/RemoveDocument",
  INVOICES_PUBLISHER_GET_INVOICE_DOCUMENTS:
    "/PublisherInvoice/{invoiceId}/GetDocuments",
  INVOICES_PUBLISHER_GENERATE_INVOICE: "/PublisherInvoice/Generate",
  INVOICES_PUBLISHER_GENERATE_RE_INVOICE:
    "/PublisherInvoice/ReGenerate?{queryParams}",

  // REPORT
  REPORT_GET_MERCHANT: "/Report/GetMerchantReport",
  REPORT_GET_PUBLISHER: "/Report/GetPublisherReport",
  REPORT_GET_USER_NOTIFICATION: "/Report/GetUserNotifReport",
  REPORT_GET_ADMIN_REPORT: "/Report/GetAdminReport",
  REPORT_GET_ASYNC_REPORTS: "/Report/GetUserReportFilesSummary",
  REPORT_GET_ASYNC_RETRY: "/Report/Retry?reportId={reportId}",
  REPORT_GET_MEDIA_REPORTS: "/Report/GetPublisherMediaList",
  REPORT_GET_MEDIA_REPORTS_EXCEL: "/Report/GetPublisherMediaListExcel/excel",
  REPORT_GET_MEDIA_REPORTS_EXCEL_ASYNC:
    "/Report/RequestPublisherMediaListExcel/excelreq",
  REPORT_GET_ORDERS_COUNT_AND_AMOUNT:
    "/Report/GetOrderOverviewbyCountAndAmount",
  REPORT_GET_COMMISSION_OVERVIEW: "/Report/GetCommisionOverview",
  REPORT_GET_CLICK_IMPRESSION: "/Report/GetClickImpression",
  REPORT_GET_COST_OVERVIEW: "/Report/GetCostOverview",
  REPORT_GET_CONVERSION_RATE: "/Report/GetConversionRate",
  REPORT_GET_CONVERSION_RATE_BY_PUBLISHER:
    "/Report/GetConversionRateForPublisher",
  REPORT_GET_ORDER_OVERVIEW_BY_COUNT:
    "/Report/GetOrderOverviewbyCountAndAmount",
  REPORT_GET_LINK_CONVERSION: "/Report/GetLinkConversion",
  REPORT_GET_TOP_10_PUBLISHERS: "/Report/GetTop10Publisher",
  REPORT_GET_PUBLISHER_OVERVIEW: "/Report/GetPublisherOverView",
  REPORT_GET_TOP_10_CATEGORIES: "/Report/GetTop10Categories",
  REPORT_GET_TOP_10_CATEGORIES_BY_COUNT:
    "/Report/GetTop10CategoriesByOrderCount",
  REPORT_GET_TOP_10_MEDIAS: "/Report/GetTop10Media",
  REPORT_GET_TOP_10_LINKS: "/Report/GetTop10Link",
  REPORT_GET_MANAGEMENT_REPORT: "/Report/GetManagementReport",
  REPORT_GET_MERCHANT_OVERVIEW: "/Report/GetMerchantOverView",
  // SSP REPORT
  REPORT_GET_CLICK_SSP: "/Report/GetClick",
  REPORT_GET_CLICK_REDIRECT_SSP: "/Report/GetRedirection",
  REPORT_GET_VIEW_SSP: "/Report/GetView",
  REPORT_GET_IMPRESSION_SSP: "/Report/GetImpression",

  // MERCHANT PAYOUTS
  MERCHANT_PAYOUT_LIST: "/MerchantPayout/list",
  MERCHANT_PAYOUT_ADD: "/MerchantPayout/bill/add",
  MERCHANT_PAYOUT_EDIT: "/MerchantPayout/bill/edit",
  MERCHANT_PAYOUT_DETAIL: "/MerchantPayout/{payoutId}/detail",
  MERCHANT_PAYOUT_APPROVE: "/MerchantPayout/approve",
  MERCHANT_PAYOUT_REJECT: "/MerchantPayout/reject",

  // CAMPAIGN
  CAMPAIGN_LIST: "/Campaign/list",
  CAMPAIGN_ADD: "/Campaign/add",
  CAMPAIGN_UPDATE: "/Campaign/update",
  CAMPAIGN_UPDATE_STATUS: "/Campaign/{campaignId}/status/{status}/set",
  CAMPAIGN_DETAIL: "/Campaign/{campaignId}/get/detail",
  CAMPAIGN_MEMBER_ADD: "/Campaign/{campaignId}/member/add",
  CAMPAIGN_ASSIGN_TO_SMART: "/ProductWidget/smart/campaign/set",
  CAMPAIGN_REMOVE_SMART: "/ProductWidget/smart/campaign/delete",
  CAMPAIGN_SMART_LIST: "/ProductWidget/smart/campaign/{campaignId}/list",

  CAMPAIGN_ASSIGN_TO_BANNER: "/Banner/campaign/set",
  CAMPAIGN_REMOVE_BANNER: "/Banner/campaign/delete",
  CAMPAIGN_BANNER_LIST: "/Banner/campaign/{campaignId}/list",

  // BucketConfig
  BUCKET_CONFIG_GET: "/BucketConfig/GetBucketConfig?id={id}",
  BUCKET_CONFIG_HISTORY: "/BucketConfig/history",
  BUCKET_GET_DEFAULT: "/BucketConfig/get/default",
  BUCKET_SET: "/BucketConfig/set",

  // CALCULATION
  CALCULATION_LIST_FORMULAS: "/Calculation/GetFormulaList",
  CALCULATION_ADD_FORMULA: "/Calculation/AddFormule",
  CALCULATION_EDIT_FORMULA: "/Calculation/UpdateFormule",
  CALCULATION_GET_FORMULA: "/Calculation/GetFormule?formulaId={formulaId}",
  CALCULATION_GET_FLOWS: "/Calculation/GetFlowList",
  CALCULATION_GET_VARIABLES_LIST: "/Calculation/GetVariableList",

  // ProductWidget
  INVENTORY_WIDGET_ADD: "/inventory/add",
  INVENTORY_WIDGET_DELETE: "/inventory/{id}/delete",
  INVENTORY_WIDGET_CLONE: "/inventory/{id}/clone",
  INVENTORY_WIDGET_GET: "/inventory/{id}/get",
  INVENTORY_WIDGET_UPDATE: "/inventory/update",
  INVENTORY_WIDGET_SMART_GET_LIST: "/inventory/{id}/getcode",
  INVENTORY_WIDGET_LIST: "/inventory/getlist",
};

export const ERROR_STATUS = {
  default: "default",
  processing: "processing",
  pending: "pending",
  warning: "warning",
  success: "success",
  danger: "danger",
  error: "error",
};

export const ERROR_CODES = {
  ACCOUNT_NOT_VERIFIED: -2003,
};

export const SORT_ORIENTATION = {
  DESCENDING: 1,
  ASCENDING: 2,
};

export const GENDER = {
  FEMALE: 1,
  MALE: 2,
};

export const COMPANY_TYPE = {
  PRIVATE: 1,
  PUBLIC: 2,
  LIMITED: 3,
};

export const LEGAL_STATUS = {
  PRIVATE: 1,
  LEGAL: 2,
};

export const USER_TYPE = {
  PUBLISHER: 1,
  MERCHANT: 2,
  SUPER_ADMIN: 3,
  ADMIN: 4,
  SERVICE: 5,
  ADVERTISER: 6,
};

export const USER_TYPE_REGISTER = {
  PUBLISHER: 1,
  MERCHANT: 2,
};

export const USER_TYPE_SSP = {
  // SERVICE: 5,
  ADVERTISER: 6,
};

export const CONTENT_TYPE = {
  EMAIL_SUBJECT: 1,
  EMAIL_BODY: 2,
  REGISTER_SMS_TEXT: 3,
  PUBLISHER_TERMS_AND_CONDITIONS: 4,
  MERCHANT_TERMS_AND_CONDITIONS: 5,
};

export const SEND_CODE_CONSUMER = {
  REGISTER: 1,
  LOGIN: 2,
  FORGOT_PASSWORD: 3,
  CHANGE_MOBILE: 4,
};

export const FILE_OBJECT_TYPE = {
  USER: 1,
  PUBLISHER: 2,
  MERCHANT: 3,
  CONTRACT: 4,
  WEB_STORE_LEVEL: 5,
  INVOICE: 6,
};

export const FILE_MEDIA_TYPE = {
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  DOCUMENT: 4,
  EXCEL: 5,
};

export const FILE_FILE_TYPE = {
  NATIONAL_CARD: 1,
  BIRTH_CERTIFICATE: 2,
  VAT_DOCUMENT: 3,
  NEWSPAPER_DOCUMENT: 4,
  PAPER_CONTRACT: 5,
  CATEGORY_COMMISSION: 6,
  BANNER_IMAGE: 7,
  PROFILE_IMAGE: 8,
  BILL: 9,
  Report: 10,
  LegalPublisherPaymentBill: 11,
  PublisherInvoiceDocument: 12,
  WebStoreLogo: 13,
  CampaignItems: 14,
};

export const CONTRACT_STATUS = {
  NEW: 1,
  APPROVED: 2,
  REJECTED: 3,
  EXPIRED: 4,
  BLOCKED: 5,
};

export const CONTRACT_TYPE = {
  NEW: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const CONTRACT_STATUS_ALERT = {
  NEW: ERROR_STATUS.default,
  APPROVED: ERROR_STATUS.success,
  REJECTED: ERROR_STATUS.error,
  EXPIRED: ERROR_STATUS.warning,
  BLOCKED: ERROR_STATUS.error,
};

export const CONTRACT_TEMPLATE_TYPE = {
  Merchant: 1,
  PrivatePublisher: 2,
  LegalPublisher: 3,
};

export const CONTRACT_TEMPLATE_STATUS = {
  New: 1,
  Approved: 2,
  Expired: 3,
  Rejected: 4,
  Blocked: 5,
};

export const CONTRACT_TEMPLATE_STATUS_ALERT = {
  New: ERROR_STATUS.default,
  Waiting: ERROR_STATUS.default,
  Approved: ERROR_STATUS.success,
  Rejected: ERROR_STATUS.error,
  Expired: ERROR_STATUS.error,
};

export const VERIFY_STATUS = {
  UnVerify: 0,
  Pending: 1,
  Failed: 2,
  Verified: 3,
};

export const VERIFY_STATUS_ALERT = {
  UnVerify: ERROR_STATUS.default,
  Pending: ERROR_STATUS.processing,
  Failed: ERROR_STATUS.danger,
  Verified: ERROR_STATUS.success,
};

export const KYC_STATUS_OLD = {
  UNVERIFIED: 1,
  EMAIL_VERIFIED: 2,
  PERSONAL_INFO_VERIFIED: 3,
  FULL_VERIFIED: 4,
  PENDING_PERSONAL_VERIFYING: 5,
  PENDING_FULL_VERIFYING: 6,
  REJECT_PERSONAL_INFO: 7,
  REJECT_FULL_VERIFYING: 8,
};

export const KYC_STATUS = {
  PersonalInfoConfirmed: 1,
  CompanyInfoConfirmed: 2,
  BankAccountInfoConfirmed: 3,
  ContactInfoConfirmed: 4,
  NationalCardConfirmed: 5,
  IdentityCardConfirmed: 6,
  VatDocumentConfirmed: 7,
  NewsPaperDocumentConfirmed: 8,
};

export const KYC_STATUS_PRIVATE = {
  PersonalInfoConfirmed: 1,
  BankAccountInfoConfirmed: 3,
  ContactInfoConfirmed: 4,
  NationalCardConfirmed: 5,
  IdentityCardConfirmed: 6,
  VatDocumentConfirmed: 7,
};

export const KYC_STATUS_LEGAL = {
  // PersonalInfoConfirmed: 1,
  CompanyInfoConfirmed: 2,
  ContactInfoConfirmed: 4,
  VatDocumentConfirmed: 7,
  NewsPaperDocumentConfirmed: 8,
};

export const USER_STATUS = {
  Registering: 1,
  PendingForApproval: 2,
  InReview: 3,
  KysError: 4,
  Confirmed: 5,
  Rejected: 6,
  Blocked: 7,
};

export const DOCUMENTS_STATUS = {
  rejected: 1,
  approved: 2,
  waiting: 3,
};

export const USER_STATUS_ALERT = {
  Registering: ERROR_STATUS.default,
  PendingForApproval: ERROR_STATUS.processing,
  InReview: ERROR_STATUS.warning,
  Confirmed: ERROR_STATUS.success,
  Rejected: ERROR_STATUS.error,
  Blocked: ERROR_STATUS.error,
  KysError: ERROR_STATUS.error,
};

export const STATUS_DOC = {
  approved: "approved",
  error: "error",
};

export const CONTRACT_TEMPLATE_VARIABLES = [
  "{FirstName}",
  "{LastName}",
  "{FullName}",
  "{FatherName}",
  "{NationalCode}",
  "{CompanyName}",
  "{Mobile}",
  "{Email}",
  "{PostalCode}",
  "{Username}",
  "{Sheba}",
  "{RegisterNumber}",
  "{NationalId}",
  "{ContractDate}",
  "{ContractStartDate}",
  "{ContractEndDate}",
  "{EconomicalNumber}",
  "{LegalRepresentative}",
  "{Address}",
  "{PhoneNumber}",
  "{RegisterDate}",
  "{CompanyType}",
];

export const CURRENCY = {
  Dollar: "Dollar",
  Euro: "Euro",
  Rial: "Rial",
  Toman: "Toman",
};

export const CURRENCY_TYPE = {
  Rial: 1,
  Toman: 2,
  Euro: 3,
  Dollar: 4,
};

export const COOKIE_TIME_TYPE = [
  // { key: "Second", value: 1 },
  { key: "Minute", value: 2 },
  { key: "Hour", value: 3 },
  { key: "Day", value: 4 },
  { key: "Month", value: 5 },
  { key: "Year", value: 6 },
];

export const MEDIA_TYPE = {
  Website: 1,
  Instagram: 2,
  Telegram: 3,
  // LinkedIn: 4,
  // WhatsApp: 5,
  // Facebook: 6,
  YouTube: 7,
  // WeChat: 8,
  // Messenger: 9,
  // TikTok: 10,
  Aparat: 11,
  // Pinterest: 12,
  // TelegramBot: 13,
};

export const MEDIA_TYPE_AVAILABLE = {
  Website: 1,
  Instagram: 2,
  Telegram: 3,
  YouTube: 7,
  Aparat: 11,
};

export const TEST_MODE = {
  PendingForApprove: 1,
  InTestMode: 2,
  ActiveUser: 3,
};

export const MEDIA_STATUS = {
  InTestMode: 1,
  Active: 2,
};

export const CONTENT_STATUS = {
  New: 1,
  Approved: 2,
  Rejected: 3,
  Block: 4,
};

export const OWNERSHIP_STATUS = {
  New: 1,
  Approved: 2,
  Rejected: 3,
};

export const REASONS_TYPE = {
  Media: 1,
  Contract: 2,
  UserDocument: 3,
  Commission: 4,
  Payout: 5,
};

export const COLOR_ENABLED = {
  true: ERROR_STATUS.success,
  false: ERROR_STATUS.error,
};

export const USER_CONFIG_TYPE = {
  FirstApprove: 1,
  Culture: 2,
  FirstLogin: 3,
};

export const LINK_TYPES = {
  Private: 1,
  Public: 2,
  BannerWidget: 3,
  ProductWidget: 4,
  Promotion: 5,
};

export const FIRST_APPROVE_ACCEPTED = "1";

export const COMMISSIONS_LEVEL_STATUS = {
  PendingForApprove: 0,
  Active: 1,
  Approved: 2,
  Rejected: 3,
  Closed: 4,
  Ended: 5,
};

export const OrderStatusEnum = {
  New: 1,
  MerchantApproved: 2,
  Finalize: 3,
  Canceled: 4,
  MerchantRejected: 5,
};

export const OrderPaymentStatus = {
  WaitingForInvoice: 1,
  Invoiced: 2,
  Paid: 3,
};

export const StatusType = {
  ok: true,
  no: false,
};

export const InvoiceStatus = {
  Initial: 1,
  Confirmed: 2,
  Rejected: 3,
  Pending: 4,
};

export const PaymentStatus = {
  WaitingToProcess: 1,
  ReadyForPayment: 2,
  TreasuryAcceptance: 3,
  TreasuryRejected: 4,
  PaymentBlock: 5,
  Paid: 6,
};

export const PayoutStatus = {
  Waiting: 1,
  Approved: 2,
  Rejected: 3,
};

export const InvoiceEventType = {
  CostPerOrder: 1,
  CostPerOrderByDeductingVoucher: 2,
  NewCustomerRegistration: 3,
  NewCustomerOrder: 4,
  Bounty: 5,
};

export const ProjectionStatus = {
  Initial: 1,
  WaitingToCalculate: 2,
  WaitingToGenerate: 3,
  Calculated: 4,
  CalculatedWithZeroResult: 5,
};

export const IntervalInvoice = [5, 10, 15, 20, 25, 30];

export const CALCULATION_CATEGORY_LEVEL = {
  Leaf: 0,
  Closest: -2,
  Root: -1,
  Custom: 1,
};

export const WIDGET_TYPE = {
  Manual: 0,
  Smart: 1,
};

export const REPORT_QUEUE_STATUS = {
  QUEUED: 1,
  FAILED: 2,
  SUCCEEDED: 3,
  IN_PROGRESS: 4,
};

export const REPORT_TYPE = {
  ORDER: 1,
  MEDIA: 2,
  LINK: 3,
};

export const LINK_REPORT_TYPE = {
  LINK: "LINK",
  BANNER: "BANNER",
  WIDGET: "WIDGET",
};

export const ProfileInfoErrorList = {
  first_name: "first_name",
  last_name: "last_name",
  sur_name: "sur_name",
  father_name: "father_name",
  date_of_birth: "date_of_birth",
  national_code: "national_code",
  identity_code: "identity_code",
  gender: "gender",
  mobile: "mobile",
  email: "email",
  phone_number: "phone_number",
  postal_code: "postal_code",
  address: "address",
  plaque: "plaque",
  sheba: "sheba",
  has_vat: "has_vat",
  company_name: "company_name",
  register_number: "register_number",
  company_type: "company_type",
  national_id: "national_id",
  economical_number: "economical_number",
  legal_representative_first_name: "legal_representative_first_name",
  legal_representative_last_name: "legal_representative_last_name",
};

export const LANGUAGE = {
  PERSIAN: "fa",
  ENGLISH: "en",
};

export const COMMISSION_TYPE = {
  Percent: 1,
  FixPrice: 2,
  Formula: 3,
};

export const CAMPAIGN_TYPE = {
  Any: 1,
  Both: 4,
  Category: 2,
  Product: 3,
};

export const CAMPAIGN_MEMBER_TYPE = {
  Bucket: 1,
  Publisher: 2,
  Media: 3,
  Level: 4,
};

export const CAMPAIGN_STATUS = {
  Waiting: 1,
  Active: 2,
  Pause: 3,
  Ended: 4,
};

export const CAMPAIGN_EVENT_TYPE = {
  CostPerOrder: 1,
  NewCustomer: 2,
  // Bounty: 3,
};

export const BUCKET_TYPE = {
  Diamond: 1,
  Gold: 2,
  Silver: 3,
  Bronze: 4,
};

export const FAQ_TABIDS = {
  Profile: 7309,
  Medias: 7312,
  Links: 7324,
  Orders: 7326,
  Financials: 7328,
};

export const WEB_STORE_AMOUNT_TYPE = {
  OriginalAmount: 1,
  AmountWithDiscount: 2,
};

export const WEB_STORE_RELATION_TYPE = {
  FULL: 1,
  ONLY: 2,
  EXCEPT: 3,
};

export const FILTER_DATE_BY = {
  ORDER_DATE: 1,
  FINALIZE_DATE: 2,
  INVOICE_DATE: 3,
};

export const CODE_LANGUAGE = {
  JavaScript: 1,
  React: 2,
  Angular: 3,
  Php: 4,
  Django: 5,
  DotNet: 6,
  Java: 7,
  Python: 8,
  Android: 9,
  Ios: 10,
};

export const MEDIA_DESTINATION = {
  Other: 0,
  WebStoreLandingPage: 1,
  // ProductPage: 2,
  // SearchPage: 3,
  // CampaignPage: 4,
};

/**
 * SSP
 */
export const INVENTORY_TYPE = {
  native: 1,
  banner: 2,
};

export const DEVICE_TYPE = {
  Desktop: 3,
  Tablet: 2,
  Mobile: 1,
};

export const INVENTORY_FORMATS = {
  InFeed: 1,
  Multiplex: 2,
  Catfish: 3,
  PushDown: 4,
  InArticle: 5,
  Popup: 6,
  Float: 7,
  Fixed: 8,
  Slider: 9,
};

export const INVENTORY_STATUS = {
  unknown: 0,
  active: 1,
  inActive: 2,
  expired: 3,
};

export const INVENTORY_POSITION_TYPE = {
  Any: 0,
  TopRight: 1,
  TopLeft: 2,
  BottomRight: 3,
  BottomLeft: 4,
  Top: 5,
  Bottom: 6,
  Right: 7,
  Left: 8,
};

export const CONTENT_DISPLAY_TYPE = {
  // Any: 0,
  ImageOnTop: 1,
  ImageOnLeft: 2,
  ImageOnRight: 3,
  TitleOnTop: 4,
  TitleOnBottom: 5,
  JustText: 6,
};
