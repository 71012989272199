import { verifyIranianNationalId } from "@persian-tools/persian-tools";
import { Col, Form, Input, Row, Select, message } from "antd";
import lodash from "lodash";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { clarity } from "react-microsoft-clarity";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";

import { accountUserDetail } from "@/app/local/accountSlice";
import { appLoadingBar } from "@/app/local/appSlice";
import { publisherSetPersonalInfo } from "@/app/local/publisherSlice";
import { getProfileData, isUserPage } from "@/pages/profile";
import { Privileges } from "@/utils/constants/accessCenter";
import {
  FILE_FILE_TYPE,
  FILE_MEDIA_TYPE,
  GENDER,
  KYC_STATUS,
  LEGAL_STATUS,
  USER_STATUS,
} from "@/utils/constants/apiConstants";
import {
  convertToAntDate,
  disabledDate,
  findInfoError,
  getLastError,
  objectR,
  serverAcceptableDate,
  setInputPrivileges,
} from "@/utils/helper";

import inTrackEvent from "../extra/Intrack/InTrackEvent";
import InputDatePicker from "../input/inputDatePicker";
import InputUpload from "../input/inputUpload";
import FooterWizard from "./FooterWizard";

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 12,
  },
};

export const KYC_CHECK = (profile, name) => {
  const userInfo =
    _.get(profile, "userDetail.data") || _.get(profile, "userDetail");
  const userStatus = userInfo?.user?.status;
  const kycStatus = userInfo?.user?.kyc_status || [];
  const name_ = name.length === 2 ? name[1] : name[0];

  if (userStatus === USER_STATUS.KysError) {
    if (name_ === "picIdentityNumber") {
      const isConfirmed = kycStatus.find(
        (kyc) => kyc === KYC_STATUS.IdentityCardConfirmed
      );
      if (!isConfirmed) return "error";
      return "success";
    }
    if (name_ === "picNationalCode") {
      const isConfirmed = kycStatus.find(
        (kyc) => kyc === KYC_STATUS.NationalCardConfirmed
      );
      if (!isConfirmed) return "error";
      return "success";
    }
    if (name_ === "sheba") {
      const isConfirmed = kycStatus.find(
        (kyc) => kyc === KYC_STATUS.BankAccountInfoConfirmed
      );
      if (!isConfirmed) return "error";
      return "success";
    }
    if (name_ === "picVatDocument") {
      const isConfirmed = kycStatus.find(
        (kyc) => kyc === KYC_STATUS.VatDocumentConfirmed
      );
      if (!isConfirmed) return "error";
      return "success";
    }
    if (name_ === "picPaperDocument") {
      const isConfirmed = kycStatus.find(
        (kyc) => kyc === KYC_STATUS.NewsPaperDocumentConfirmed
      );
      if (!isConfirmed) return "error";
      return "success";
    }
  } else if (userStatus === USER_STATUS.Confirmed) {
    return "success";
  }
};

const StepPersonalInfo = (props) => {
  const isProfile = useMatch("/profile");
  const isUser = isUserPage();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [respError, setRespError] = useState(null);

  const profile = getProfileData();
  const { stepPersonal, loading, error } = useSelector(
    (state) => state.publisher
  );
  const userInfo = _.get(profile, "userDetail");
  const uId = profile?.data?.user_id;

  console.log("salam", userInfo);

  useEffect(() => {
    if (!_.isEmpty(userInfo)) {
      const bthDate = userInfo.date_of_birth
        ? convertToAntDate(userInfo.date_of_birth)
        : "";
      form.setFieldsValue({
        user: {
          legal: t(`auth.${objectR(LEGAL_STATUS)[userInfo.legal_status]}`),
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          fatherName: userInfo.father_name,
          gender: objectR(GENDER)[userInfo.gender],
          birthday: bthDate,
          nationalCode: userInfo.national_code,
          identityNumber: userInfo.identity_code,
        },
      });
    }

    if (isProfile) {
      setInputPrivileges(
        "user",
        Object.keys(Privileges.publisher.profile),
        form
      );
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(appLoadingBar(loading));
  }, [loading]);

  const successFullyDone = () => {
    message.success({
      content: t("general.saveSuccessFully"),
      key: "publisher",
    });
    if (_.isFunction(props?.goNextStep)) {
      if (!userInfo?.first_name) {
        dispatch(accountUserDetail());
      }
      props.goNextStep(1);
    }
  };

  const validateMessages = {
    required: t("required"),
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = async (values) => {
    const {
      firstName,
      lastName,
      fatherName,
      birthday,
      nationalCode,
      identityNumber,
      gender,
      picIdentityNumber,
      picNationalCode,
    } = values.user;
    const eventParam = {
      userId: uId,
      firstName,
      lastName,
      gender: gender === "MALE" ? "male" : "female",
      phone: "+98" + userInfo?.user?.mobile,
      attributes: {
        kycStatus: 3,
        fatherName,
        nationalCode,
        identityNumber,
        isIndividual: true,
      },
    };
    console.log("eventParam", eventParam);
    inTrackEvent.updateProfile(eventParam);
    clarity.setTag("register", "step-1");

    const IFile = picIdentityNumber[0]?.response?.file_id;
    const nFile = picNationalCode[0]?.response?.file_id;
    const dtBth = serverAcceptableDate(birthday);
    const finalNationalCode =
      nationalCode.length == 9 ? 0 + nationalCode : nationalCode;

    const serverData = {
      // user_id: uId,
      legal_status: LEGAL_STATUS.PRIVATE,
      first_name: firstName,
      last_name: lastName,
      sur_name: firstName,
      father_name: fatherName,
      date_of_birth: dtBth,
      national_code: finalNationalCode,
      identity_code: identityNumber,
      gender: GENDER[gender],
      national_card_file_id: nFile,
      birth_certificate_file_id: IFile,
    };
    dispatch(appLoadingBar(true));
    const response = await dispatch(publisherSetPersonalInfo(serverData));
    if (response?.payload?.success) {
      // InTrack Event
      inTrackEvent.personalInfoEvent({
        userId: userInfo?.user_id,
        ...serverData,
      });

      successFullyDone();
    } else {
      const errorMessage =
        response?.payload?.Message || getLastError(response?.payload);
      const keyName = findInfoError(response?.payload?.data);
      message.error({
        content: errorMessage,
        key: "publisher",
      });
      setRespError(keyName);
    }
  };

  const isFull = props.md === 24;

  return (
    <>
      <Form
        form={form}
        name="formStepOne"
        onFinish={onFinish}
        validateMessages={validateMessages}
        // className="afl-form"
        layout="vertical"
        // labelCol={{
        //   span: 22,
        // }}
        // wrapperCol={{
        //   span: 24,
        // }}
      >
        <Row>
          <Col md={props.md}>
            <Row gutter={24}>
              {isProfile ? (
                <Col md={8} xs={24}>
                  <Form.Item name={["user", "legal"]} label={t("user.legal")}>
                    <Input disabled />
                  </Form.Item>
                </Col>
              ) : (
                ""
              )}
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "firstName"]}
                  label={t("user.firstName")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={isUser} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "lastName"]}
                  label={t("user.lastName")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={isUser} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "fatherName"]}
                  label={t("user.fatherName")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input readOnly={isUser} />
                </Form.Item>
              </Col>
              {/* </Row> */}
              {/* <Row gutter={24}> */}
              <Col md={3} xs={24}>
                <Form.Item
                  name={["user", "gender"]}
                  label={t("user.gender")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select disabled={isUser}>
                    {Object.keys(GENDER).map((key) => (
                      <Select.Option key={key} value={key}>
                        {t("user." + key)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={5} xs={24}>
                <Form.Item
                  name={["user", "birthday"]}
                  label={t("user.birthday")}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  {/* //TODO REMOVE INPUT READONLY */}
                  <InputDatePicker
                    disabledDate={disabledDate}
                    style={{ width: "100%" }}
                    disabled={isUser}
                    inputReadOnly
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "nationalCode"]}
                  label={t("user.nationalCode")}
                  rules={[
                    {
                      required: true,
                    },
                    () => ({
                      validator(_, value) {
                        if (verifyIranianNationalId(String(value))) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("user.notValidNationalCode"))
                        );
                      },
                    }),
                  ]}
                  hasFeedback={
                    respError?.includes("national_code") ? true : false
                  }
                  validateStatus={
                    respError?.includes("national_code") && "error"
                  }
                >
                  <Input
                    maxLength="10"
                    style={{ width: "100%" }}
                    readOnly={isUser}
                  />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  name={["user", "identityNumber"]}
                  label={t("user.identityNumber")}
                  rules={[
                    {
                      required: true,
                      max: 10,
                    },
                    () => ({
                      validator(_, value) {
                        if (
                          !lodash.isNaN(Number(value)) &&
                          lodash.isNumber(Number(value))
                        ) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("user.notValidIdentNumber"))
                        );
                      },
                    }),
                  ]}
                  hasFeedback={
                    respError?.includes("identity_code") ? true : false
                  }
                  validateStatus={
                    respError?.includes("identity_code") && "error"
                  }
                >
                  <Input maxLength="10" readOnly={isUser} />
                </Form.Item>
              </Col>
            </Row>
            <br />
            <Row gutter={24}>
              <Col md={isFull ? 12 : 8} xs={24}>
                <InputUpload
                  label={t("user.picIdentityNumber")}
                  name={["user", "picIdentityNumber"]}
                  fileType={FILE_FILE_TYPE.BIRTH_CERTIFICATE}
                  mediaType={FILE_MEDIA_TYPE.IMAGE}
                  required
                  form={form}
                  files={[userInfo?.birth_certificate_file_id]}
                  accept="image"
                  checkKYC
                />
              </Col>

              <Col md={isFull ? 12 : 8} xs={24}>
                <InputUpload
                  label={t("user.picNationalCode")}
                  name={["user", "picNationalCode"]}
                  fileType={FILE_FILE_TYPE.NATIONAL_CARD}
                  mediaType={FILE_MEDIA_TYPE.IMAGE}
                  required
                  form={form}
                  files={[userInfo?.national_card_file_id]}
                  accept="image"
                  checkKYC
                />
              </Col>
            </Row>

            <FooterWizard
              loading={loading}
              current={0}
              goNextStep={props.goNextStep}
            />
          </Col>
          <Col></Col>
        </Row>
      </Form>
    </>
  );
};

StepPersonalInfo.propTypes = {
  goNextStep: PropTypes.func,
  md: PropTypes.number,
};
StepPersonalInfo.defaultProps = {
  md: 18,
};

export default StepPersonalInfo;
